import APIModel from "../APIModel";

export default class Auth extends APIModel {
  constructor() {
    super("jwt");
  }

  async login(payload = {}) {
    return await this.http.ignoreErrorHandler().post(`${this.resourceUrl}/login`, payload);
  }

  async logout() {
    return await this.http.get(`${this.resourceUrl}/logout`, {});
  }

  async forgotPassword(payload = {}) {
    return await this.http.ignoreErrorHandler().post(`forgot_password`, payload);
  }

  async resetPassword(token: string, payload = {}) {
    return await this.http.ignoreErrorHandler().post(`reset_password/${token}`, payload);
  }

  async checkResetToken(token: string) {
    return await this.http.get(`check_reset_token/${token}`, {});
  }

  async refreshToken(payload = {}) {
    return await this.http.ignoreErrorHandler().post(`${this.resourceUrl}/refresh-token`, payload);
  }

  async getUser(payload = {}) {
    return await this.http
      .ignoreErrorHandler(400, 403, 404, 405, 408, 422, 426, 429, 500, -1)
      .get(`${this.resourceUrl}/user`, payload);
  }
}
