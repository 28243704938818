<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const isExternal = computed(() => {
  return props.item.link.startsWith("http");
});
</script>

<template>
  <div :title="props.item.text">
    <div v-if="props.item.children">
      <slot name="default"></slot>
    </div>
    <a v-else-if="isExternal" :href="props.item.link" class="text-white">
      <slot name="default"></slot>
    </a>
    <router-link v-else :to="props.item.link" class="text-white">
      <slot name="default"></slot>
    </router-link>
  </div>
</template>

<style lang="scss" scoped></style>
