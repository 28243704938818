<script>
export default {
  name: "EmptyLayout",
};
</script>

<script setup>
import { useRoute } from "vue-router";

const route = useRoute();
</script>

<template>
  <div data-type="empty">
    <router-view v-slot="{ Component }">
      <transition mode="out-in" name="zoom-fade">
        <component :is="Component" :id="`${route.name}__container`" />
      </transition>
    </router-view>
  </div>
</template>
