import API from "@/services/api/API";
import { Store } from "vuex";
import store from "@/store";
import { Router } from "vue-router";

class UserService {
  private router: Router;
  private store: Store<unknown>;
  private loadUserInterval: any;
  private readonly loadUserTimeout: number;

  constructor(router: Router) {
    this.router = router;
    this.store = store;
    this.loadUserInterval = null;
    this.loadUserTimeout = 60000 * 3;
    //this.loadUserTimeout = 5000;
  }

  public async load() {
    const response = await API.auth().getUser();

    this.setResponse(response);

    if ("serviceWorker" in navigator) {
      await this.initSW();
    } else {
      setTimeout(() => {
        this.load();
      }, this.loadUserTimeout);
    }
  }

  public clearLoadInterval() {
    if (this.loadUserInterval) {
      clearInterval(this.loadUserInterval);
    }
  }

  private async initSW() {
    this.listenSW();
  }

  private listenSW() {
    navigator.serviceWorker.addEventListener("message", async ({ data }) => {
      const [firstClient] = data.clients;

      if (data.data.type === "check" && (data.clients.length < 2 || data.client === firstClient)) {
        const response = await API.auth().getUser();

        this.setResponse(response);

        navigator.serviceWorker.controller?.postMessage({
          type: "result",
          ...response,
        });
      }

      if (data.data.type === "result" && data.client !== firstClient) {
        this.setResponse(data.data);
      }
    });

    this.loadUserInterval = setInterval(() => {
      if (document.hasFocus()) {
        navigator.serviceWorker.controller?.postMessage({
          type: "check",
        });
      }
    }, this.loadUserTimeout);
  }

  private setResponse(response: any) {
    this.store.dispatch("auth/setUser", response);
    this.store.dispatch("auth/setOffers", response.offers);

    if (response.notifications) {
      this.showNotification(response.notifications);
    }
  }

  private showNotification(notifications: any[]) {
    const existNotifications = store.getters["alert/messages"].map(({ id }: any) => id);
    const popupCategories = ["critical", "popup"];
    const fullScreenCategories = ["critical"];

    notifications
      .filter((item) => !existNotifications.includes(item.id))
      .forEach(({ id, color: type, subject: title, message, category }: any) => {
        const text = message;

        this.store.dispatch("alert/show", {
          id,
          type: type || "",
          title,
          modal: popupCategories.includes(category),
          fullScreen: fullScreenCategories.includes(category),
          // author: `${author} (${created_at})`,
          text,
          withoutClosing: true,
          onClick: async () => {
            // await this.router.push(`/notifications/all/${id}`);
          },
          onClickClose: async () => {
            await API.notifications().read(id);
          },
        });
      });
  }
}

export default UserService;
