import { NavigationGuardNext, Router } from "vue-router";
import store from "@/store";
import { Store } from "vuex";
import UserService from "@/services/UserService";
import NavigationService from "@/services/NavigationService";
import LocalizationService from "@/services/LocalizationService";
import ThemeService from "@/services/ThemeService";

class RouterMiddleware {
  private router: Router;
  private store: Store<unknown>;
  private user: UserService;
  private navigation: NavigationService;
  private localization: LocalizationService;
  private theme: ThemeService;

  constructor(router: Router) {
    this.router = router;
    this.store = store;
    this.user = new UserService(router);
    this.navigation = new NavigationService();
    this.localization = new LocalizationService();
    this.theme = new ThemeService();
  }

  public apply() {
    this.applyBeforeEach();
    this.applyAfterEach();
  }

  private applyAfterEach() {
    this.router.afterEach(async () => {
      await this.hideGlobalLoader();
    });
  }

  private applyBeforeEach() {
    this.router.beforeEach(async (to, from, next: NavigationGuardNext) => {
      try {
        await this.theme.load();
        await this.localization.load(to);

        const isAuthorized = this.store.getters["auth/hasAccessToken"];
        const user = this.store.getters["auth/user"];

        if (!to.meta?.withoutCredentials && !isAuthorized) {
          next(`/auth/login`);
          return;
        }

        if (isAuthorized && !user) {
          await Promise.all([this.user.load(), this.navigation.load()]);
        }

        next();
      } catch (error: any) {
        if (error.message) {
          await this.store.dispatch("alert/show", {
            title: error.error,
            text: error.message,
            type: "error",
          });
        }

        this.user.clearLoadInterval();

        next("/error/unknown");
      }
    });
  }

  private async hideGlobalLoader() {
    store.dispatch("preloader/hideGlobal");
  }
}

export default RouterMiddleware;
