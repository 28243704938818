import { Store } from "vuex";
import { Router } from "vue-router";
import store from "@/store";
import router from "@/router";
import API from "@/services/api/API";
import Http from "@/services/api/http";

class ErrorHandler {
  private store: Store<any>;
  private static instance: ErrorHandler;
  private pendingRefresh: boolean;

  constructor() {
    this.store = store;
    this.pendingRefresh = false;
  }

  public static getInstance(): ErrorHandler {
    if (!ErrorHandler.instance) {
      ErrorHandler.instance = new ErrorHandler();
    }

    return ErrorHandler.instance;
  }

  globalHandlerIgnored(ignoredErrors: Array<number>, error: any): boolean {
    const response = error.response?.request;
    const status = response?.status;

    return ignoredErrors.includes(status);
  }

  async handle(ignoredErrors: Array<number>, error: any): Promise<any> {
    const response = error.response?.request;
    const status = response?.status;

    if (!status && ignoredErrors.includes(-1)) {
      return;
    }

    if (ignoredErrors.includes(status)) {
      return;
    }

    switch (status) {
      case 301:
        return this.movedPermanently();
      case 303:
        return this.seeOther(response);
      case 400:
        return this.badRequest();
      case 401:
        return this.unauthorized(error.config);
      case 402:
        return this.paymentRequired();
      case 403:
        return this.forbidden();
      case 404:
        return this.notFound();
      case 405:
        return this.methodNotAllowed();
      case 408:
        return this.timeOut();
      case 422:
        return this.formValidation();
      case 426:
        return this.passwordExpired();
      case 429:
        return this.TooManyRequests();
      case 500:
        return this.serverError();
      case 504:
        return this.gatewayTimeout();
      default:
        return this.unknown();
    }
  }

  async badRequest() {
    await router.push("/error/404");
  }

  async seeOther(response: any) {
    try {
      await router.push(JSON.parse(response.response).redirect);
    } catch (e) {
      await Promise.resolve();
    }
  }

  async unauthorized(config: any) {
    try {
      const http = new Http();

      if (!this.pendingRefresh) {
        this.pendingRefresh = true;

        const { refresh_token } = this.store.getters["auth/credentials"];
        const response = await API.auth().refreshToken({
          refreshToken: refresh_token,
        });

        await this.store.dispatch("auth/login", response);

        return http.getAxiosInstance().request(config);
      }

      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (!this.pendingRefresh) {
            clearInterval(intervalId);
            resolve("");
          }
        });
      });

      return http.getAxiosInstance().request(config);
    } catch (e) {
      await this.store.dispatch("auth/logout");
    } finally {
      this.pendingRefresh = false;
    }
  }

  async forbidden() {
    await router.push("/error/403");
  }

  async notFound() {
    await router.push("/error/404");
  }

  async unknown() {
    await router.push("/error/unknown");
  }

  async methodNotAllowed() {
    await router.push("/error/404");
  }

  async TooManyRequests() {
    // await router.push("/error/404");
    await Promise.resolve();
  }

  async timeOut() {
    await router.push("/error/404");
  }

  async formValidation() {
    await router.push("/error/404");
  }

  async passwordExpired() {
    await router.push("/error/404");
  }

  async serverError() {
    await router.push("/error/500");
  }

  async movedPermanently() {
    await router.push("/error/404");
  }

  async paymentRequired() {
    await router.push("/error/404");
  }

  async gatewayTimeout() {
    await router.push("/error/504");
  }
}

export default ErrorHandler.getInstance();
