const meta = {
  module: "search-tour",
};

export default [
  {
    path: "/search/tour",
    name: "search-tour",
    component: () => import(/* webpackChunkName: "search-tour" */ `@/modules/${meta.module}/index.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "search-tour",
    },
  },
];
