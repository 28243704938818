<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed } from "vue";

const store = useStore();
const router = useRouter();

const phones = computed(() => {
  const { user } = store.getters["auth/credentials"];

  return user.phones;
});
</script>

<template>
  <div v-if="phones">
    <div
      v-popup="{
        placement: 'bottom',
        trigger: 'click',
        delay: [100, 100],
      }"
    >
      <div data-popup="handler">
        <ui-icon class="cursor-pointer" color="var(--bs-white)" name="phone-alt" size="24"></ui-icon>
      </div>
      <div data-popup="content">
        <div class="bg-white radius bordered contacts p-3">
          <div class="fs-14px">
            <div class="text-primary fw-bold text-uppercase">{{ $t("global.contacts.booking_department") }}</div>
            <template v-if="phones.reservation_department.office">
              <div class="text-gray-3 fs-12px mt-1">{{ $t("global.contacts.office_phone") }}</div>
              <div class="fw-bold">{{ phones.reservation_department.office }}</div>
            </template>
            <template v-if="phones.reservation_department.mobile">
              <div class="text-gray-3 fs-12px mt-1">{{ $t("global.contacts.mobile_phone") }}</div>
              <div class="fw-bold">{{ phones.reservation_department.mobile }}</div>
            </template>
          </div>
          <template v-if="phones.accounting">
            <div class="divider-element my-2"></div>
            <div class="fs-14px">
              <div class="text-primary font-weight-bold fw-bold text-uppercase">
                {{ $t("global.contacts.accounting") }}
              </div>
              <div class="fw-bold mt-1">{{ phones.accounting }}</div>
            </div>
          </template>
          <template v-if="phones.ticket_office">
            <div class="divider-element my-2"></div>
            <div class="fs-14px">
              <div class="text-primary font-weight-bold fw-bold text-uppercase">
                {{ $t("global.contacts.ticket_office") }}
              </div>
              <div class="fw-bold mt-1">{{ phones.ticket_office }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.contacts {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  width: auto;
}
</style>
