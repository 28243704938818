export default {
  global: {
    contacts: {
      ticket_office: "Авиакасса:",
      accounting: "Бухгалтерия:",
      booking_department: "Отдел бронирования:",
      office_phone: "Тел. офис:",
      mobile_phone: "Тел. моб:",
    },
    hotel_details: {
      title: "Детали отеля",
      labels: {
        contacts: "Контакты",
        address: "Адрес",
        phones: "Телефон",
        emails: "Электронная почта",
        hygiene: "Гигиена",
        urls: "Ссылки",
        sports_entertainment: "Спорт",
        meals: "Питание",
        room_facilities: "Размешение",
        facilities: "Номера",
        payment: "Оплата",
        building_info: "Информация об объекте",
        category: "Категория",
        object_information: "Контактные данные",
        extra_fee: "доп. оплата",
      },
    },
    baggage_info: {
      adult_title: "Взрослые или дети от 12 лет",
      inf_title: "Инфанты:",
      max_luggage: "Бесплатный багаж (макс.):",
      max_handle_luggage: "Ручная кладь (макс.):",
      size: "Размер:",
      kg: "кг",
      no: "нет",
      registration_message:
        "Регистрация начинается за {open} часа до вылета, и закрывается за {close} минут до вылета.",
      details: "Подробнее",
    },
    price_decoding: {
      hotel: "Отель",
      daily_price: "Суточные цены",
      discounts: "Скидки",
      tickets: "Билеты",
      mark_up: "Фирма",
      sum: "Сумма",
      transfer: "Трансфер",
      insurance: "Страховка",
      neverland: "Neverland",
      commission: "Комиссия",
      agency_commission: "Коммиссия агентства",
      total: "Итого",
      aquapark_services: "Aquapark entry",
      never_land_entrance: "Neverland Night Show",
      handling_fee: "Handling Fee",
      gala_dinner_extra: "Gala dinner",
      net_package_cost: "Цена нетто",
      there: "Туда",
      back: "Обратно",
      services_amount: "Сумма услуг",
      total_net_amount: "Итоговая сумма нетто",
      total_tickets: "Итого билеты",
      hotel_mark_up: "Mark up",
      taxes: "Таксы",
    },
    menu: {
      news: "Новости",
      tour: "Поиск тура",
      orders: "Журнал пакетов",
      plane: "Поиск авиабилетов",
      employees: "Сотрудники агентства",
      bonuses: "Журнал бонусов",
      docs: "Документы",
      rules: "Правила",
      faq: "FAQ",
      subpartitions: "Субблоки",
      agencies: "Управление агентством",
      flights: "Список авиабилетов",
      user_links: "Привязанные пользователи",
      payment_gateways: "Платежные шлюзы",
      ats: {
        orders: "Список авибилетов",
        sales_report: "Отчет по продажам",
        user_links: "Привязанные пользователи",
        orders_payments: "Оплаты",
      },
    },
    currency: {
      empty: "Курс на сегодня не установлен",
      ratio: "Соотношение: {ratio} EUR/USD нижн",
      EUR: "€",
      USD: "$",
    },
    measure_units: {
      percent: "%",
      EUR: "€",
      USD: "$",
    },
    menu_search: "Поиск",
    Home: "Главная",
    not_selected: "Не выбрано",
    select_date: "Выберите дату",
    "no-results": "Извините, подходящих вариантов нет.",
    all: "Все",
    clear_all: "Очистить все",
    reset: "Сбросить",
    search: "Поиск",
    from: "с",
    enter: "Введите",
    exist: "Доступен",
    no: "Недоступно",
    to: "по",
    age: "лет (инфант) | год (инфант) | года | лет",
    Phone: "Телефон",
    Name: "Имя",
    full_name: "ФИО",
    addContact: "Добавить контакт",
    attention: "Внимание!",
    select: "Выберите",
    selected: "Выбрано",
    selected_count: "Выбрано {count} из {total}",
    noOptionsText: "Список пуст",
    comments: "Комментарий",
    type_comment: "Введите комментарий",
    show_all: "Показать все",
    form: {
      ph_last_name: "Введите фамилию",
      ph_first_name: "Введите имя",
      ph_date: "Введите дату",
      ph_number: "Введите номер",
      ph_fiscal_code: "Фискальный код",
      title: "Название фирмы",
      phone: "Введите телефон",
      email: "Введите емаил",
      address: "Адрес фирмы",
    },
    copy: "Копировать",
    clipboard_copy: "Скопировь в буфер обмена",
    clipboard_copy_success: "Текст скопирован",
    clipboard_link_copy_success: "Ссылка была успешно скопирована",
    table: {
      result_length: "Всего результатов:",
      per_page: "Показать",
      filters: "Фильтры",
      no_results: "Извините, подходящих вариантов нет.",
      date: "Дата",
      success_create_message: "Запись была успешно добавлена",
      success_edit_message: "Запись была успешно обновлена",
      success_delete_message: "Запись была успешно удалена",
    },
    labels: {
      from: "От",
      to: "До",
      min_price: "Минимальная цена",
      max_price: "Максимальная цена",
      average_price: "Средняя цена",
      price: "Цена",
      search_by_results: "Поиск по результатам",
      departure: "Вылет",
      arrival: "Прилет",
      copy: "Скопировать",
      copy_row: "Скопировать ряд",
    },
    buttons: {
      profile: "Профиль",
      logout: "Выход",
      reset: "Сбросить",
      save: "Сохранить",
      sort: "Cортировать",
      by_default: "По умолчанию",
      cancel: "Отмена",
      search: "Поиск",
      print: "Печать",
      confirm: "Подтвердить",
      clear: "Очистить",
      update: "Обновить",
      add: "Добавить",
      save_to_pdf: "Сохранить в PDF",
      reset_contacts: "Контакты по умолчанию",
      import_from_file: "Загрузить из файла",
      load: "Загрузить",
    },
    print_error: "Печать данной страницы недоступно",
    flight_there: "Туда",
    flight_back: "Оттуда",
    flight: "Перелет",
    in_flight: "В полете",
    saved_offer: {
      title: "Персональное предложение",
      accommodation_dates: "Даты проживания",
      nutrition: "Питание",
      room_type: "Тип комнаты",
      accommodation: "Размещение",
      name: "название",
      nights: "{nights} ночь | {nights} ночей | {nights} ночи | {nights} ночей",
    },
    rules: {
      min_date: "Дата должна быть больше {date}",
      max_date: "Дата должна быть меньше {date}",
      decimal: "Поле должно быть числом",
    },
    book: {
      flight_info: "Включено: регистрируемый багаж макс. 20 кг и ручная кладь макс. 6 кг/чел.",
    },
    messages: {
      delete: "Удаление",
      delete_answer: "Вы уверенны что хотите удалить выбранный элемент?",
      delete_all_answer: "Вы уверенны что хотите удалить все выбранные элемент?",
      empty_list: "Не найдено подходящих записей",
      required_fields: "Поля, отмеченные звездочкой (*), обязательны для заполнения.",
    },
    weekdays: {
      mon: "Понедельник",
      tue: "Вторник",
      wed: "Среда",
      thu: "Четверг",
      fri: "Пятница",
      sat: "Суббота",
      sun: "Воскресенье",
    },
  },
};
