import ApiModel from "../APIModel";

export default class Employees extends ApiModel {
  constructor() {
    super("v1/employees");
  }

  async block(id: number, payload: object): Promise<any> {
    return await this.http.ignoreErrorHandler(403, 422).post(`${this.resourceUrl}/${id}/block`, payload);
  }

  async create(): Promise<any> {
    return await this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/create`, {});
  }

  async getEditedFields(id: number): Promise<any> {
    return await this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/${id}/edit `, {});
  }

  async edit(id: number, payload: object): Promise<any> {
    return await this.http.ignoreErrorHandler(500).post(`${this.resourceUrl}/${id}/edit `, payload);
  }

  post(payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(422).post(`${this.resourceUrl}/create`, payload);
  }

  async dismiss(id: number, payload: object): Promise<any> {
    return await this.http.ignoreErrorHandler(403, 422).post(`${this.resourceUrl}/${id}/dismiss`, payload);
  }

  async hire(id: number, payload: object): Promise<any> {
    return await this.http.ignoreErrorHandler(403, 422).post(`${this.resourceUrl}/${id}/hire`, payload);
  }

  async unblock(id: number, payload: object): Promise<any> {
    return await this.http.ignoreErrorHandler(403, 422).post(`${this.resourceUrl}/${id}/unblock`, payload);
  }
}
