<script setup>
import { computed, nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import API from "@/services/api/API";
import Stars from "@/components/tour/Stars";
import moment from "@/plugins/moment";
import FlightOffer from "@/components/form/FlightOffer";
import { breakpointsBootstrapV5, useBreakpoints } from "@vueuse/core";
import i18n from "@/plugins/i18n";
import useRowCopy from "@/hooks/use-row-copy";

const { t, tc } = useI18n();
const store = useStore();

const showOverlay = ref(false);
const showModal = ref(false);
const model = ref([]);
const loading = ref(false);
const printContainer = ref(null);
const contacts = ref(null);

const breakpoints = useBreakpoints(breakpointsBootstrapV5);
const { copyToClipboard, copyAllToClipboard } = useRowCopy();
const isMobile = breakpoints.smaller("md");

const offersCount = computed(() => store.getters["auth/offers"]);

watch(
  () => showModal.value,
  (value) => {
    if (value) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "";
    }
  },
  {
    immediate: true,
  }
);

const closeDialog = () => {
  showModal.value = false;

  nextTick(() => {
    showOverlay.value = false;
  });
};

const openDialog = async () => {
  await loadData();

  if (!contacts.value) {
    await loadContacts();
  }

  showOverlay.value = true;

  await nextTick(() => {
    showModal.value = true;
  });
};

const loadContacts = async () => {
  try {
    contacts.value = await API.search().contacts();
  } catch (error) {
    closeDialog();
    await store.dispatch("alert/show", {
      title: error.error,
      text: error.message,
      type: "error",
    });
  }
};

const loadData = async () => {
  loading.value = true;

  try {
    const response = await API.search().getOffers();

    model.value = response.map((item) => ({
      ...item,
      price: item.price || item.search_result.price.amount,
    }));
  } catch (error) {
    closeDialog();
    await store.dispatch("alert/show", {
      title: error.error,
      text: error.message,
      type: "error",
    });
  }

  loading.value = false;
};

const editPrice = async (uuid, price, item) => {
  const oldPrice = item.price.amount;

  if (Number(price) < oldPrice) {
    price = oldPrice;
  }

  try {
    await API.search().patchOffers(uuid, {
      price,
    });
    await loadData();
  } catch (error) {
    await store.dispatch("alert/show", {
      title: error.error,
      text: error.message,
      type: "error",
    });
  }
};

const printToPDF = async () => {
  // const printArea = document.createElement("div");
  // const printError = document.getElementById("print-error");
  // const content = printContainer.value.cloneNode(true);
  //
  // printError.classList.add("disable-print");
  // content.classList.add("printable", "full-height", "overflow-visible");
  //
  // printArea.append(content);
  // document.body.append(printArea);
  // window.print();
  // printError.classList.remove("disable-print");
  // document.body.removeChild(printArea);

  try {
    const response = await API.search().printOffers(contacts.value);
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `offer_${moment().format("DD.MM.YYYY_")}${moment().format("hh:mm:ss")}.pdf`);
    link.click();
  } catch (error) {
    await store.dispatch("alert/show", {
      title: error.error,
      text: error.message,
      type: "error",
    });
  }
};

const remove = async (payload) => {
  try {
    await API.search().deleteOffers(payload);
    await loadData();
    const count = await API.search().totalOffers();

    await store.dispatch("auth/setOffers", count);
  } catch (error) {
    closeDialog();
    await store.dispatch("alert/show", {
      title: error.error,
      text: error.message,
      type: "error",
    });
  }
};

// показывать, когда кратно 4
const showHeader = (index) => {
  return index % 4 === 0;
};
</script>

<template>
  <div>
    <div class="position-relative cursor-pointer" @click="openDialog()">
      <ui-icon color="var(--bs-white)" name="shopping-basket" size="24"></ui-icon>
      <div v-if="offersCount" class="fs-14px fw-bold px-1 text-green icon-badge" data-test="added-offers">
        {{ offersCount }}
      </div>
    </div>
    <Transition name="fade">
      <div
        v-if="showOverlay"
        class="dialog d-flex align-items-center justify-content-center"
        v-handle-esc="closeDialog"
        @click="closeDialog()"
      >
        <Transition name="slide">
          <div v-if="showModal" class="dialog__card radius text-gray-1" @click.stop>
            <div class="dialog__card--header d-flex align-items-center justify-content-between p-3">
              <div class="fw-bold fs-18px text-white">{{ t("global.saved_offer.title") }}</div>
              <ui-icon class="cursor-pointer" color="white" name="multiply" size="18px" @click="closeDialog()" />
            </div>
            <div class="dialog__card--body">
              <b-card class="border-0 mb-2" no-body>
                <div class="py-md-3 px-md-4 py-2 px-3">
                  <div class="d-flex justify-content-between align-items-center">
                    <input
                      v-model="contacts.title"
                      :placeholder="$t('global.form.title')"
                      class="fs-22px fw-bold text-gray-2 title-input"
                    />
                    <div class="text-center text-nowrap fw-bold fs-22px text-gray-2 d-none d-lg-block">
                      {{ $t("global.saved_offer.title") }}
                    </div>
                  </div>
                  <div class="text-gray-1 mt-2 d-flex align-items-center">
                    <ui-icon color="var(--gray-3)" name="location-point" size="18px" />
                    <input
                      v-model="contacts.address"
                      :placeholder="$t('global.form.address')"
                      class="fw-medium ms-2 ps-1"
                    />
                  </div>
                  <div class="text-gray-1 mt-2 d-flex align-items-center">
                    <ui-icon color="var(--gray-3)" name="phone" size="18px" />
                    <input
                      v-model="contacts.phone"
                      :placeholder="$t('global.form.phone')"
                      class="fw-medium ms-2 ps-1"
                    />
                  </div>
                  <div class="text-gray-1 mt-2 d-flex align-items-center">
                    <ui-icon color="var(--gray-3)" name="envelope" size="18px" />
                    <input
                      v-model="contacts.email"
                      :placeholder="$t('global.form.email')"
                      class="fw-medium ms-2 ps-1"
                    />
                  </div>
                  <button
                    class="h-40 w-100 btn btn-outline-primary fs-16px d-md-none align-items-center justify-content-center pt-2 mb-2 mt-3 pb-2 ps-3 pe-3 d-block"
                    style="min-width: initial"
                    type="button"
                    @click="loadContacts()"
                  >
                    <div class="w-100 text-nowrap">{{ $t("global.buttons.reset_contacts") }}</div>
                  </button>
                  <button
                    class="h-40 w-100 btn btn-outline-primary fs-16px d-md-none align-items-center justify-content-center pt-2 mb-2 mt-2 pb-2 ps-3 pe-3 d-block"
                    style="min-width: initial"
                    type="button"
                    @click="copyAllToClipboard(model)"
                  >
                    <div class="d-flex align-items-center justify-content-center w-100">
                      <ui-icon name="clipboard-alt" size="20"></ui-icon>
                      <div class="ms-2">{{ $t("global.copy") }}</div>
                    </div>
                  </button>
                </div>
              </b-card>

              <TransitionGroup class="order-container p-0 p-sm-3 position-relative" name="list-item" tag="div">
                <div v-for="({ search_result: item, uuid }, index) in model" :key="uuid" class="mb-3 order-card">
                  <section>
                    <b-card class="border-0 radius" no-body>
                      <div
                        class="px-3 py-2 my-1 order-container__title d-flex align-items-start align-items-lg-center justify-content-between"
                      >
                        <div>
                          <div class="d-flex align-items-center">
                            <div class="me-md-4 me-2 fs-20px">
                              {{ index + 1 }}
                            </div>
                            <div class="d-flex align-items-center py-0 ps-1 py-md-2">
                              <input
                                v-model="model[index].price"
                                v-maska="'######'"
                                class="price-input"
                                @blur="editPrice(uuid, model[index].price, item)"
                              />
                              <div class="me-3 pe-1 text-primary fs-20px fw-bold">
                                {{ $t("global.currency.EUR") }}
                              </div>
                            </div>
                          </div>
                          <div class="d-flex align-items-center mt-2 mt-lg-0 pt-1 pt-lg-0">
                            <div class="fs-18px text-gray-1 fw-bold ms-lg-4 text-nowrap">
                              {{ moment(item?.dates?.check_in).format("DD.MM.YYYY") }} -
                              {{ moment(item?.dates?.check_out).format("DD.MM.YYYY") }}
                            </div>
                            <div class="fs-18px text-gray-3 ms-2 text-nowrap">
                              ({{ item?.nights?.total }} {{ $tc("global.saved_offer.nights", item?.nights?.total) }})
                            </div>
                          </div>
                        </div>
                        <div class="d-flex align-items-center row-actions">
                          <div class="me-2 cursor-pointer" @click="copyToClipboard(item, model[index].price)">
                            <ui-icon color="var(--primary)" name="clipboard-alt" size="24px"></ui-icon>
                          </div>
                          <div
                            :key="uuid"
                            v-confirm="{
                              title: $t('global.messages.delete'),
                              message: $t('global.messages.delete_answer'),
                              callback: () => remove([uuid]),
                            }"
                            class="disable-print"
                          >
                            <ui-icon color="var(--danger)" name="trash-alt" size="24px"></ui-icon>
                          </div>
                        </div>
                      </div>
                    </b-card>
                    <div class="px-3 d-md-none">
                      <div class="divider-element"></div>
                    </div>
                  </section>
                  <section class="mt-0 mt-md-1">
                    <b-card class="border-0 radius d-none d-md-block" no-body>
                      <div class="p-2">
                        <div class="booking-hotel-grid">
                          <div class="hotel">
                            <div class="d-flex">
                              <div class="me-3">
                                <b-avatar
                                  :src="require('@/assets/images/no-hotel.png')"
                                  rounded="lg"
                                  size="64"
                                  variant="primary-light"
                                />
                              </div>
                              <div>
                                <div class="fs-12px text-gray-3 text-uppercase text-semibold">
                                  {{ $t("global.saved_offer.name") }}
                                </div>
                                <div class="d-flex align-items-center my-1">
                                  <div class="fs-14px text-gray-1 text-uppercase text-bold pe-3">
                                    {{ item?.accommodation?.hotel?.name }}
                                  </div>
                                </div>
                                <div class="d-flex align-items-center">
                                  <div
                                    v-if="item?.accommodation?.hotel?.category"
                                    class="fs-12px text-gray-1 text-uppercase text-semibold"
                                  >
                                    <template v-if="item?.accommodation?.hotel?.category.substr(1, 1) === '*'">
                                      <stars :ratio="+item?.accommodation?.hotel?.category.substr(0, 1)" short />
                                    </template>
                                    <template v-else>{{ item?.accommodation?.hotel?.category }}</template>
                                  </div>
                                  <div class="fs-12px text-gray-3 text-uppercase ms-2">
                                    {{ item?.accommodation?.hotel?.city }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="room">
                            <div class="fs-12px text-gray-3 text-uppercase text-semibold">
                              {{ $t("global.saved_offer.room_type") }}
                            </div>
                            <div class="my-1 fs-14px text-gray-1 pe-3">{{ item?.accommodation?.room?.name }}</div>
                            <div class="fs-12px text-gray-3">{{ item?.accommodation?.placement?.name }}</div>
                          </div>
                          <div class="meal">
                            <div class="fs-12px text-gray-3 text-uppercase text-semibold">
                              {{ $t("global.saved_offer.nutrition") }}
                            </div>
                            <div class="mt-1 fs-14px text-gray-1">{{ item?.accommodation?.meal?.full_name }}</div>
                          </div>
                        </div>
                      </div>
                    </b-card>
                    <b-card class="border-0 radius d-md-none" no-body>
                      <div>
                        <div class="hotel px-3 pt-2 mt-1 mt-md-0 pt-md-3 pb-2">
                          <div class="d-flex align-items-center">
                            <div class="me-3">
                              <b-avatar
                                :src="require('@/assets/images/no-hotel.png')"
                                rounded="lg"
                                size="72"
                                variant="primary-light"
                              />
                            </div>
                            <div>
                              <div class="fs-10px text-gray-3 text-uppercase text-semibold mb-1">
                                {{ $t("global.saved_offer.name") }}
                              </div>
                              <div class="d-flex align-items-center mb-1">
                                <div class="fs-14px text-gray-1 text-uppercase text-bold">
                                  {{ item?.accommodation?.hotel?.name }}
                                </div>
                              </div>
                              <div class="d-flex align-items-center">
                                <div
                                  v-if="item?.accommodation?.hotel?.category"
                                  class="fs-12px text-gray-1 text-uppercase text-semibold"
                                >
                                  <template v-if="item?.accommodation?.hotel?.category.substr(1, 1) === '*'">
                                    <stars :ratio="+item?.accommodation?.hotel?.category.substr(0, 1)" />
                                  </template>
                                  <template v-else>{{ item?.accommodation?.hotel?.category }}</template>
                                </div>
                                <div class="fs-12px text-gray-3 text-uppercase ms-2">
                                  {{ item?.accommodation?.hotel?.city }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="room ps-3 pt-2 pb-2 pe-3">
                          <div class="fs-12px text-gray-3 text-uppercase text-semibold">
                            {{ $t("global.saved_offer.room_type") }}
                          </div>
                          <div class="mt-1 fs-14px fw-medium text-gray-1">{{ item?.accommodation?.room?.name }}</div>
                        </div>
                        <div class="meal ps-3 p1-2 pe-3 d-flex align-end justify-content-between">
                          <div class="fs-14px text-uppercase">
                            {{ $t("global.saved_offer.accommodation") }}
                          </div>
                          <div class="dotted-delimiter"></div>
                          <div class="fs-14px text-semibold text-nowrap">
                            {{ item?.accommodation?.placement?.name }}
                          </div>
                        </div>
                        <div class="meal ps-3 pt-1 pb-2 mb-1 pe-3 d-flex align-end justify-content-between">
                          <div class="fs-14px text-uppercase">
                            {{ $t("global.saved_offer.nutrition") }}
                          </div>
                          <div class="dotted-delimiter"></div>
                          <div class="fs-14px text-semibold text-nowrap">
                            {{ item?.accommodation?.meal?.full_name }}
                          </div>
                        </div>
                        <div class="px-3">
                          <div class="divider-element"></div>
                        </div>
                      </div>
                    </b-card>
                  </section>
                  <section v-if="item?.tickets?.from" class="mt-0 mt-md-1">
                    <b-row gutter-x="2">
                      <b-col class="px-1" cols="12" lg="6">
                        <flight-offer :item="item?.tickets?.from" class="h-100" />
                      </b-col>
                      <b-col class="px-3 d-md-none" cols="12">
                        <div class="divider-element"></div>
                      </b-col>
                      <b-col class="px-1 ps-lg-0" cols="12" lg="6">
                        <flight-offer :item="item?.tickets?.to" flip class="h-100" />
                      </b-col>
                    </b-row>
                  </section>
                </div>
                <div v-if="!model.length" class="my-3 text-gray-3 text-center px-5 mx-5 mt-2">
                  {{ $t("global.messages.empty_list") }}
                </div>
              </TransitionGroup>
            </div>
            <div class="dialog__card--footer px-3 py-2">
              <div class="fs-24px d-flex justify-content-between py-1">
                <div v-if="model.length">
                  <button
                    v-confirm="{
                      title: $t('global.messages.delete'),
                      message: $t('global.messages.delete_all_answer'),
                      callback: () => remove([]),
                    }"
                    class="h-40 w-100 btn btn-outline-danger fs-14px d-flex align-items-center justify-content-center pt-2 pb-2 ps-3 pe-3 mb-2 mb-md-0"
                    type="button"
                  >
                    <div class="w-100">{{ $t("global.buttons.clear") }}</div>
                  </button>
                </div>
                <div v-else></div>
                <div :class="{ 'w-100': isMobile }" class="d-md-flex align-items-center d-block ps-2">
                  <!--                  <button-->
                  <!--                    @click="loadData()"-->
                  <!--                    class="h-40 btn btn-outline-secondary w-100 fs-16px d-flex align-items-center justify-content-center pt-2 pb-2 ps-3 pe-3 me-2 mb-2 mb-sm-0"-->
                  <!--                    type="button"-->
                  <!--                    :disabled="loading"-->
                  <!--                  >-->
                  <!--                    <span-->
                  <!--                      v-if="loading"-->
                  <!--                      aria-hidden="true"-->
                  <!--                      class="spinner-border spinner-border-sm"-->
                  <!--                      role="status"-->
                  <!--                    ></span>-->
                  <!--                    <ui-icon v-else class="mt-1" name="sync" size="18px"></ui-icon>-->
                  <!--                    <div class="ml-2 w-100">{{ $t("global.buttons.update") }}</div>-->
                  <!--                  </button>-->
                  <button
                    class="h-40 w-100 btn btn-outline-primary fs-16px d-md-flex align-items-center justify-content-center me-0 me-md-2 pt-2 mb-2 mb-md-0 pb-2 ps-2 pe-2 d-none"
                    style="min-width: initial"
                    type="button"
                    @click="copyAllToClipboard(model)"
                  >
                    <div class="d-flex align-items-center justify-content-center w-100">
                      <ui-icon name="clipboard-alt" size="20"></ui-icon>
                      <div class="ms-2">{{ $t("global.copy") }}</div>
                    </div>
                  </button>
                  <button
                    class="h-40 w-100 btn btn-outline-primary fs-16px d-md-flex align-items-center justify-content-center me-0 me-md-2 pt-2 mb-2 mb-md-0 pb-2 ps-3 pe-3 d-none"
                    style="min-width: initial"
                    type="button"
                    @click="loadContacts()"
                  >
                    <div class="w-100 text-nowrap">{{ $t("global.buttons.reset_contacts") }}</div>
                  </button>
                  <button
                    v-if="model.length"
                    :disabled="loading"
                    class="h-40 w-100 btn btn-primary fs-16px d-flex align-items-center justify-content-center pt-2 pb-2 ps-3 pe-3"
                    style="min-width: initial"
                    type="button"
                    @click="printToPDF()"
                  >
                    <div class="w-100 text-nowrap">{{ $t("global.buttons.save_to_pdf") }}</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

* {
  line-height: 1.2;
}

.page-break-before {
  page-break-before: always;
}

.row-actions {
  position: absolute;
  right: 8px;
  top: 20px;
}

.dialog {
  position: fixed;
  width: 100vw;
  height: 100%;
  z-index: 1030;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);

  &__card {
    width: 1200px;
    //min-width: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 87vh;
    background: var(--bs-body-bg);

    &--header {
      background: var(--primary);
      padding: 13px 15px 13px 25px;
      position: sticky;
      z-index: 22;
      top: 0;
    }

    &--body {
      overflow-y: auto;
      overflow-x: hidden;
    }

    &--footer {
      background: var(--bs-white);
      z-index: 2;
      flex-wrap: wrap;
      position: sticky;
      bottom: 0;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    }
  }
}

input {
  border-top: none;
  border-left: none;
  border-right: none;
  width: 100%;
  color: var(--gray-1);
  height: initial !important;
  border-bottom: 1px solid var(--gray-5);
}

.price-input {
  font-size: 20px;
}

@media print {
  input {
    border-bottom: none;
  }

  .order-container {
    &__title {
      & > div:first-child {
        & > div:first-child {
          width: 27px;
          background: var(--primary);
          border-radius: 4px;
          color: var(--bs-white);
          text-align: center;
          line-height: 28px;
          font-weight: bold;
        }

        & > div:nth-child(2) {
          & > input {
            background: var(--bs-white);
            color: var(--primary);
            font-weight: bold;
            border-bottom: none;
            text-align: right;
            width: 68px !important;
          }

          border: 1px solid var(--gray-4);
          border-radius: 4px;
          padding-top: 8px;
          padding-bottom: 8px;
          line-height: 20px;
          min-height: 36px;
        }
      }
    }
  }
}

.order-container {
  &__title {
    & > div:first-child {
      display: grid;
      grid-template-columns: auto auto;

      & > div:first-child {
        & > div:first-child {
          width: 27px;
          background: var(--primary);
          border-radius: 4px;
          color: var(--bs-white);
          text-align: center;
          line-height: 28px;
          font-weight: bold;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        & > div:nth-child(2) {
          & > input {
            width: 99px;
            background: var(--bs-white);
            color: var(--primary);
            font-weight: bold;
            border-bottom: none;
            text-align: right;
          }

          border: 1px solid var(--gray-4);
          border-radius: 4px;
          padding-top: 8px;
          padding-bottom: 8px;
          line-height: 20px;
          min-height: 36px;
        }
      }
    }
  }
}

.booking-hotel-grid {
  display: grid;
  grid-template-columns: 2fr 1.2fr 0.8fr;
}

//@include media-breakpoint-only(xs) {
//  .booking-hotel-grid {
//    grid-template-columns: 1fr;
//  }
//}
//
//@include media-breakpoint-only(sm) {
//  .booking-hotel-grid {
//    grid-template-columns: 1fr;
//  }
//}
//
//@include media-breakpoint-only(md) {
//  .booking-hotel-grid {
//    grid-template-columns: 1fr;
//  }
//}

.fade-enter-active,
.slide-enter-active {
  transition: all 0.4s ease-out;
}

.fade-leave-active,
.slide-leave-active {
  transition: all 0.4s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(50vh);
}

.icon-badge {
  background: var(--bs-white);
  border-radius: 10px;
  position: absolute;
  top: -6px;
  right: -12px;
}

.title-input {
  width: 50%;
}

@include media-breakpoint-down(md) {
  .dialog {
    &__card {
      height: 100vh;
      width: 100vw;
    }

    .order-card {
      background: #ffffff;
      border-radius: 8px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    }

    .order-container {
      &__title {
        & > div:first-child {
          display: grid;
          grid-template-columns: 1fr;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .title-input {
    width: 100%;
  }

  .dialog {
    &__card {
      max-height: 100%;
    }
  }

  .order-container {
    &__title {
      & > div:first-child {
        display: grid;
        grid-template-columns: 1fr;
      }
    }
  }
}

.list-item-move,
.list-item-enter-active,
.list-item-leave-active {
  transition: all 0.8s ease;
}

.list-item-enter-from,
.list-item-leave-to {
  opacity: 0;
}

.list-item-leave-active {
  position: absolute;
  left: 0;
  width: 100%;
}

.divider {
  border-bottom: 1px solid var(--gray-6);
}

.dotted-delimiter {
  width: 100%;
  height: 14px;
  border-bottom: 1px dashed var(--gray-4);
  margin-left: 6px;
  margin-right: 6px;
}
</style>
