let externHandler: (e: any) => void;

export default {
  mounted(el: any, bindings: any) {
    const handler = (e: any) => {
      if (e.code === "Escape") {
        bindings.value();
      }
    };

    externHandler = handler;

    window.addEventListener("keydown", handler);
  },
  unmounted() {
    window.removeEventListener("keydown", externHandler);
  },
};
