import { createI18n } from "vue-i18n";
import store from "@/store";

const currentLang = store.getters["localization/getCurrent"];
const allowedLand = store.getters["localization/getAllowed"];
const messages: { [value: string]: any } = {};

for (const lang of allowedLand) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  messages[lang] = require(`@/locales/${lang}.ts`).default;
}

export default createI18n({
  locale: currentLang,
  fallbackLocale: currentLang,
  pluralizationRules: {
    ru: (choice: number, choicesLength: number) => {
      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }
      return choicesLength < 4 ? 2 : 3;
    },
  },
  messages,
});
