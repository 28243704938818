<script setup>
import VSelect from "vue-select";
import { Field } from "vee-validate";
import { defineEmits, defineProps, ref, watch } from "vue";
import cloneDeep from "lodash/fp/cloneDeep";
import { createPopper } from "@popperjs/core";

const props = defineProps({
  modelValue: {
    type: [String, Number, Array, Object],
  },
  options: {
    type: Array,
  },
  name: {
    type: String,
    default: () => "Field" + Math.floor(Math.random() * 100),
  },
  rules: {
    type: String,
    default: () => "",
  },
  icon: {
    type: [Array, String],
    default: () => null,
  },
  label: {
    type: String,
    default: () => "",
  },
  customIcon: {
    type: Boolean,
    default: false,
  },
  searchable: {
    type: Boolean,
    default: false,
  },
  hideSearch: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  autofill: {
    type: Boolean,
    default: false,
  },
  reduce: {
    type: Function,
    default: (e) => e.id,
  },
});

const emit = defineEmits(["update:modelValue"]);
const input = ref(props.modelValue);
const isEmit = ref(false);

// watch(
//   () => props.options,
//   (value) => {
//     if (props.autofill) {
//       if (value?.find((el) => el?.id === props.modelValue || el === props.modelValue) === undefined) {
//         if (value?.length === 1 && !props.disabled) {
//           if (props.multiple) {
//             emit("update:modelValue", [props.reduce(value[0])]);
//           } else {
//             emit("update:modelValue", props.reduce(value[0]));
//           }
//         } else {
//           emit("update:modelValue", null);
//         }
//       }
//     }
//   },
//   {
//     immediate: true,
//   }
// );

watch(
  () => cloneDeep(props.modelValue),
  (currentValue, oldValue) => {
    if (JSON.stringify(currentValue) !== JSON.stringify(oldValue)) {
      input.value = currentValue;
    }
  }
);

const withPopper = (dropdownList, component, { width }) => {
  dropdownList.style.width = width;
  const popper = createPopper(component.$refs.toggle, dropdownList, {
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -1],
        },
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          component.$el.classList.toggle("drop-up", state.placement === "top");
        },
      },
    ],
  });
  return () => popper.destroy();
};
</script>

<template>
  <Field v-slot="{ meta }" v-model="input" :label="label" :name="name" :rules="rules">
    <div
      :class="{
        'input-icon': !!icon,
        'hide-search': !!hideSearch,
        'w-100': true,
        'input-prepend-icon': !!icon,
        disabled: $attrs.disabled,
      }"
      class="select"
    >
      <template v-if="icon">
        <ui-icon :name="icon" class="inline-icon" size="17" />
      </template>
      <v-select
        v-model="input"
        :calculate-position="withPopper"
        :class="{ danger: meta.validated && !meta.valid }"
        :disabled="disabled"
        :multiple="multiple"
        :options="options"
        :reduce="reduce"
        :searchable="searchable"
        append-to-body
        :id="`${props.name}-select`"
        :deselect-from-dropdown="true"
        @update:modelValue="emit('update:modelValue', $event)"
        v-bind="$attrs"
      >
        <template #header>
          <slot name="header" />
        </template>
        <template #list-footer>
          <slot name="list-footer" />
        </template>
        <template #list-header>
          <slot name="list-header" />
        </template>
        <template #open-indicator="slotData">
          <slot name="open-indicator" v-bind="{ ...slotData }"></slot>
        </template>
        <template #option="slotData">
          <slot name="option" v-bind="{ ...slotData }" />
        </template>
        <template #no-options="slotData">
          <slot name="no-options" v-bind="{ ...slotData }">{{ $t("global.no-results") }}</slot>
        </template>
        <template #search="slotData">
          <slot name="search" v-bind="{ ...slotData }" />
        </template>
        <template #selected-option="slotData">
          <slot name="selected-option" v-bind="{ ...slotData }" />
        </template>
        <template #selected-option-container="slotData">
          <slot name="selected-option-container" v-bind="{ ...slotData }" />
        </template>
        <template #spinner="slotData">
          <slot name="spinner" v-bind="{ ...slotData }" />
        </template>
      </v-select>
    </div>
  </Field>
</template>

<style lang="scss" scoped>
.inline-icon {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  color: var(--gray-3);
}

.hide-search:deep(.vs--disabled .vs__dropdown-toggle) {
  background: #f2f2f2;

  .vs__search {
    display: none;
  }
}

.select:deep(.vs__dropdown-toggle) {
  min-height: 38px;
  height: 38px;

  .vs__search {
    width: 100%;
  }

  &:hover .vs__open-indicator {
    fill: var(--primary);
  }
}
</style>
