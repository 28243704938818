const meta = {
  module: "notifications",
};

export default [
  {
    path: "/notifications",
    redirect: "/notifications/all",
  },
  {
    path: "/notifications/:category/:id?",
    name: "notifications",
    component: () => import(/* webpackChunkName: "notifications" */ `@/modules/${meta.module}/index.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "notifications",
    },
  },
];
