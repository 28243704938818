<script setup>
import { computed, nextTick, ref, watch } from "vue";
import { useStore } from "vuex";
import LangSwitcher from "@/components/navigation/navbar/LangSwitcher";
import API from "@/services/api/API";
import MenuList from "@/components/navigation/aside/MenuList";

const store = useStore();

const items = store.getters["navigation/menu"];

const search = ref(null);
const queryString = ref("");

const user = computed(() => store.getters["auth/user"]);

const version = process.env.VUE_APP_VERSION;

const logout = async () => {
  try {
    await API.auth().logout();
    await store.dispatch("auth/logout");
  } catch (error) {
    await store.dispatch("alert/show", {
      title: error.error,
      text: error.message,
      type: "error",
    });
  }
};
</script>

<template>
  <div
    class="sidebar text-light"
    @mouseenter="store.dispatch('navigation/setMenu', true)"
    @mouseleave="store.dispatch('navigation/setMenu', false)"
  >
    <div class="sidebar__version">v{{ version }}</div>
    <div v-if="user" class="sidebar__profile d-block d-md-none">
      <div class="d-flex align-items-center pb-2">
        <div>
          <img v-if="user.avatar" :src="user.avatar" alt="" class="sidebar__profile--avatar" />
          <div v-else class="sidebar__profile--avatar d-flex align-items-center justify-content-center">
            <ui-icon color="var(--bs-white)" name="user" size="18px"></ui-icon>
          </div>
        </div>
        <div class="ms-2 ps-1">
          <div class="fs-16px fw-bold">{{ user.name }}</div>
          <div class="fs-14px">{{ user.position }}</div>
        </div>
      </div>
      <router-link
        class="btn btn-outline-primary w-100 d-flex align-items-center justify-content-center mt-1"
        to="/profile"
      >
        {{ $t("global.buttons.profile") }}
      </router-link>
      <!--      <div class="d-flex align-items-center mt-3 justify-content-between">-->
      <!--        <div class="d-flex d-md-none cursor-pointer" @click="logout()">-->
      <!--          <div class="text-red fs-16px fw-medium">{{ $t("global.buttons.logout") }}</div>-->
      <!--        </div>-->
      <!--        <lang-switcher class="lang-switcher d-block d-md-none p-0"></lang-switcher>-->
      <!--      </div>-->
    </div>
    <div class="sidebar__nav">
      <b-nav vertical>
        <div>
          <div class="p-2 search cursor-pointer">
            <div class="search__icon">
              <ui-icon color="var(--gray-4)" name="search" size="16"></ui-icon>
            </div>
            <input
              :placeholder="
                $store.getters['navigation/show'] || $store.getters['navigation/showMobile']
                  ? $t('global.menu_search')
                  : ''
              "
              type="text"
              v-model="queryString"
              class="search__input"
              :style="{
                'padding-left':
                  $store.getters['navigation/show'] || $store.getters['navigation/showMobile'] ? '40px' : null,
              }"
              ref="search"
            />
          </div>
        </div>
        <menu-list class="menu-container" :search-string="queryString" :list="items"></menu-list>
      </b-nav>
    </div>
    <div class="sidebar__actions">
      <div class="custom-divider"></div>
      <div class="d-flex align-content-center justify-content-between">
        <div class="px-3 my-2 py-1 d-flex d-md-none cursor-pointer" @click="logout()">
          <ui-icon color="var(--red)" name="arrow-from-right" size="18px"></ui-icon>
          <div class="text-red fs-16px fw-medium ms-3">{{ $t("global.buttons.logout") }}</div>
        </div>
        <lang-switcher class="lang-switcher d-block d-md-none"></lang-switcher>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
  z-index: 1000;

  &__version {
    bottom: 2px;
    right: 6px;
    font-size: 10px;
    position: absolute;
    opacity: 0.4;
  }

  &__profile {
    flex: 0 1 auto;
    background: #041821;
    padding: 16px;

    &--avatar {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      object-fit: cover;
      background: var(--gray-4);
    }
  }

  &__nav {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__actions {
    flex: 0 1 auto;
    background: #041821;
  }

  .custom-divider {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }
}

.btn-outline-primary {
  color: var(--bs-white);
  border-color: var(--bs-white);
}

.lang-switcher {
  padding: 12px 16px;
  font-size: 16px;
}

.search {
  position: relative;
  width: 100%;

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 17px;
  }

  &__input {
    width: 100%;
    outline: none;
    height: 35px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    color: white;
  }
}

.sidebar *::-webkit-scrollbar {
  width: 4px !important;
  background: transparent !important;
}

.sidebar *::-webkit-scrollbar-track {
  background: transparent !important;
}

.sidebar *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1) !important;
}
</style>
