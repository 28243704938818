import moment from "moment";
import "moment/locale/ru.js";
import "moment/locale/ro.js";
import store from "@/store";

const currentLang = store.getters["localization/getCurrent"];

moment.locale(currentLang);

export default moment;
