import ApiModel from "../APIModel";

export default class Offers extends ApiModel {
  constructor() {
    super("orders");
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.ignoreErrorHandler(404, 500).get(`${this.resourceUrl}/${id}/agency_edit`, {});
  }

  async countryList(): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/country_list`, {});
  }

  async submitPayment(url: string, payload: object): Promise<any> {
    return await this.http.ignoreErrorHandler(422, 500).post(url, payload);
  }

  async confirmSegmentChanges(id: string): Promise<any> {
    return await this.http.ignoreErrorHandler(422, 500).patch(`${this.resourceUrl}/${id}/charter_confirm`, {});
  }

  async getCardPaymentInfo(id: string): Promise<any> {
    return await this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/${id}/card_payment`, {});
  }

  async hotelList(payload = {}): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/hotel_list`, payload);
  }

  async ownerList(payload = {}): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/owner_list`, payload);
  }

  async flightThereList(payload = {}): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/flight_there_list`, payload);
  }

  async flightBackList(payload = {}): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/flight_back_list`, payload);
  }

  async getCancelRequest(id: number): Promise<any> {
    return await this.http.ignoreErrorHandler(403).get(`${this.resourceUrl}/${id}/cancel_request`, {});
  }

  async cancelRequest(id: number, payload: object): Promise<any> {
    return await this.http.ignoreErrorHandler(403, 422).post(`${this.resourceUrl}/${id}/cancel_request`, payload);
  }

  async getAirTicketHash(payload: object): Promise<any> {
    return await this.http.ignoreErrorHandler(403, 422).post(`${this.resourceUrl}/air_tickets/hash`, payload);
  }

  async airTicketByHash(hash: string): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/air_tickets/book/${hash}`, {});
  }

  async saveAirTicketOrder(hash: string, payload: object): Promise<any> {
    return await this.http.ignoreErrorHandler().post(`${this.resourceUrl}/air_tickets/book/${hash}`, payload);
  }

  async editAgency(id: number, payload: object): Promise<any> {
    return await this.http.ignoreErrorHandler(422).patch(`${this.resourceUrl}/${id}/agency_edit`, payload);
  }

  async printInvoice(id: number): Promise<any> {
    return await this.http.ignoreErrorHandler(404, 500).get(`${this.resourceUrl}/${id}/print_invoice`, {});
  }

  async printMemos(id: number): Promise<any> {
    return await this.http.ignoreErrorHandler(404, 500).get(`${this.resourceUrl}/${id}/print_memos`, {});
  }

  async printMedicalInsurance(id: number): Promise<any> {
    return await this.http.ignoreErrorHandler(404, 500).get(`${this.resourceUrl}/${id}/print_medical_insurance`, {});
  }

  async printFlyTickets(id: number): Promise<any> {
    return await this.http.ignoreErrorHandler(404, 500).get(`${this.resourceUrl}/${id}/print_fly_tickets`, {});
  }

  async printVoucher(id: number): Promise<any> {
    return await this.http.ignoreErrorHandler(404, 500).get(`${this.resourceUrl}/${id}/print_voucher`, {});
  }

  async printAllDocuments(id: number): Promise<any> {
    return await this.http.ignoreErrorHandler(404, 500).get(`${this.resourceUrl}/${id}/print_all_documents`, {});
  }
}
