export const DEFAULT_PATH = "default";

export const ALLOWED_DOMAINS = {
  "localhost:8080": "default",
  "192.168.0.76:8080": "default",
  //TOCOTOUR
  "new.obs.md": "obs",
  "obs.md": "obs",
  //CAPITAL
  "book.capitaltour.md": "capital",
  //HOLIDAY
  "gq-travel.md": "holiday",
  //DAMLATUR COMRAT
  "book.damlatur.md": "damla",
  //ROBINSON
  "book.robinson-travel.md": "robinson",
  //MR TRAVEL
  "book.mr-travel.md": "mister",
  //TRAPEZA
  "online.trapezatour.md": "trapeza",
  //DAMLATUR TIRASPOL
  "travel-dt.com": "damla-tiraspol",
  //SKYLINE
  "tour-reservation.com": "skyline",
};
