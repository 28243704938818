const meta = {
  module: "orders",
};

export default [
  {
    path: "/orders",
    name: "orders",
    component: () => import(/* webpackChunkName: "orders" */ `@/modules/${meta.module}/index.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "orders",
    },
  },
  {
    path: "/orders/book/:sid",
    name: "orders-book",
    component: () => import(/* webpackChunkName: "orders" */ `@/modules/${meta.module}/book.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "orders.book",
    },
  },
  {
    path: "/orders/card-payment/:id",
    name: "orders-card-payment",
    component: () => import(/* webpackChunkName: "orders" */ `@/modules/${meta.module}/card-payment.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "orders.card_payment",
    },
  },
  {
    path: "/orders/flight/:hash",
    name: "search-flight-book",
    component: () => import(/* webpackChunkName: "search-flight" */ `@/modules/search-flight/book.vue`),
    meta: {
      ...meta,
      module: "search-flight",
      titleLocalizationPath: "search-flight.book",
    },
  },
];
