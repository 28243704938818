import ApiModel from "../APIModel";

export default class FlightSearch extends ApiModel {
  constructor() {
    super("air_tickets_search");
  }

  filters(params = {}): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/filters`, params);
  }

  post(payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(404, 422).post(`${this.resourceUrl}`, payload);
  }
}
