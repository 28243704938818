const meta = {
  module: "payment-gateways",
};

export default [
  {
    path: "/payment-gateways",
    name: "payment-gateways",
    component: () => import(/* webpackChunkName: "payment-gateways" */ `@/modules/payment-gateways/index.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "payment-gateways",
    },
  },
  {
    path: "/payment-gateways/create",
    name: "payment-gateways-create",
    component: () => import(/* webpackChunkName: "payment-gateways" */ `@/modules/payment-gateways/create.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "payment-gateways.create",
    },
  },
  {
    path: "/payment-gateways/:id/edit",
    name: "payment-gateways-edit",
    component: () => import(/* webpackChunkName: "payment-gateways" */ `@/modules/payment-gateways/edit.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "payment-gateways.edit",
    },
  },
];
