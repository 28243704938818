import ApiModel from "../APIModel";

export default class Notifications extends ApiModel {
  constructor() {
    super("notifications");
  }

  read(id: number): Promise<any> {
    return this.http.ignoreErrorHandler(500).post(`${this.resourceUrl}/${id}/read`, {});
  }
}
