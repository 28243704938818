<script setup>
import { defineProps, ref, watch } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    required: false,
    default: "24",
  },
  color: {
    type: String,
    required: false,
    default: () => "",
  },
  rotate: {
    type: [Number, String],
    required: false,
    default: 0,
  },
});

const loadedComponent = ref(null);

watch(
  () => {
    return props.name;
  },
  async () => {
    try {
      const { default: htmlString } = await import(`@iconscout/unicons/svg/line/${props.name}.svg?raw`);
      const domParser = new DOMParser();
      const parsedDom = domParser.parseFromString(htmlString, "text/html");

      loadedComponent.value = parsedDom.body.firstChild;
    } catch {
      console.error(`Invalid icon name (${props.name})`);
    }
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <svg
    :width="size"
    :height="size"
    :fill="color || 'currentColor'"
    :key="name"
    v-if="loadedComponent"
    :style="{ transform: props.rotate ? `rotate(${props.rotate}deg)` : '' }"
    :viewBox="loadedComponent.attributes.viewBox.nodeValue"
    v-html="loadedComponent.innerHTML"
    class="icon"
    :class="`${props.name}__ui--icon`"
  ></svg>
</template>

<style lang="scss" scoped>
.icon {
  display: inline-block;
  font-size: inherit;
  overflow: visible;
}
</style>
