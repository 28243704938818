import CacheService from "@/services/CacheService";

export default async () => {
  const localeVersion = localStorage.getItem("version");
  const currentVersion = process.env.VUE_APP_VERSION;

  if (localeVersion !== currentVersion) {
    await CacheService.clearRouteCache("api");

    localStorage.setItem("version", currentVersion);
  }
};
