<script setup>
import { defineEmits, defineExpose, defineProps, ref, watch } from "vue";
import { Field } from "vee-validate";
import { randString } from "@/services/utils";

const emit = defineEmits(["update:modelValue", "click:append"]);

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: () => "",
  },
  name: {
    type: String,
    default: () => "Field" + Math.floor(Math.random() * 100),
  },
  rules: {
    type: String,
    default: () => "",
  },
  type: {
    type: String,
    default: () => "text",
  },
  iconText: {
    type: String,
    default: () => "",
  },
  preText: {
    type: String,
    default: () => "",
  },
  mask: {
    type: [String, Object],
    default: () => "",
  },
  showErrors: {
    type: Boolean,
    default: true,
  },
  customIcon: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: [Array, String],
    default: () => null,
  },
  appendIcon: {
    type: String,
    default: () => "",
  },
});
const id = "input__" + randString(8);

const input = ref("");
const inputElement = ref(null);

defineExpose({
  inputElement,
});

const onUpdate = (e) => {
  if (props.type === "number") {
    e.currentTarget.value = e.currentTarget.value.replace(/[^0-9.]/g, "");
  }
  emit("update:modelValue", e.currentTarget.value);
};

watch(
  () => props.modelValue,
  (currentValue) => {
    input.value = currentValue;
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <Field v-slot="{ meta }" v-model="input" :name="name" :rules="rules">
    <div
      :class="{
        'input-icon': !!icon || !!appendIcon,
        'input-append-icon': !!appendIcon,
        'input-prepend-icon': !!icon,
        'input-icon-with-text': !!iconText,
        'w-100': true,
        disabled: $attrs.disabled,
      }"
    >
      <template v-if="icon">
        <ui-icon :name="icon" class="inline-icon" style="z-index: 10" size="17" />
      </template>
      <span v-if="!!iconText" class="icon-text">{{ iconText }}</span>
      <input
        :id="`${props.name}-input`"
        ref="inputElement"
        v-model="input"
        v-maska="mask"
        :class="{ danger: meta.validated && !meta.valid }"
        :name="name"
        :type="type"
        autocomplete="off"
        class="form-control"
        v-bind="$attrs"
        @input="onUpdate"
      />
      <ui-icon
        v-if="props.appendIcon"
        :key="props.appendIcon"
        :name="props.appendIcon"
        class="inline-append-icon"
        size="18px"
        @click="emit('click:append')"
      />
    </div>
  </Field>
</template>

<style lang="scss" scoped>
.inline-append-icon {
  position: absolute;
  z-index: 10;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  color: var(--gray-3);
  cursor: pointer;
}

.input-append-icon input {
  padding-right: 35px !important;
}
</style>
