import ApiModel from "../APIModel";
import { AxiosResponse } from "axios";

export default class Giata extends ApiModel {
  constructor() {
    super("giata");
  }

  hotel(id: number): Promise<AxiosResponse> {
    return this.http.ignoreErrorHandler().get(`${this.resourceUrl}/hotel/${id}`, {});
  }

  image(id: number): Promise<AxiosResponse> {
    return this.http.ignoreErrorHandler().get(`${this.resourceUrl}/hotel/${id}`, {});
  }
}
