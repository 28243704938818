import { ActionContext } from "vuex";

interface StoreInterface {
  currentLang: string;
  allowedLanguages: Array<string>;
}

const allowedLanguages = ["ru", "ro"];
const defaultLang = allowedLanguages.includes(navigator.language)
  ? navigator.language
  : process.env.VUE_APP_DEFAULT_LOCALE;

export default {
  namespaced: true,

  state: {
    allowedLanguages,
    currentLang: (localStorage.getItem("locale") as string) || defaultLang,
  } as StoreInterface,

  mutations: {
    set(state: StoreInterface, lang: string) {
      state.currentLang = lang;
      localStorage.setItem("locale", lang);
      document.location.reload();
    },
  },

  actions: {
    set({ commit }: ActionContext<StoreInterface, "{}">, lang: string) {
      commit("set", lang);
    },
  },

  getters: {
    getCurrent: (state: StoreInterface): string => state.currentLang,
    getAllowed: (state: StoreInterface): Array<string> => state.allowedLanguages,
  },
};
