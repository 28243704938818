import store from "@/store";
import i18n from "@/plugins/i18n";

export default {
  created(el: any, bindings: any) {
    el.style.cursor = "pointer";
    el.setAttribute("title", i18n.global.t("global.clipboard_copy"));
    el.handleEvent = async (e: any) => {
      e.stopPropagation();
      await navigator.clipboard.writeText(bindings.value);
      await store.dispatch("alert/show", {
        text: i18n.global.t("global.clipboard_copy_success"),
        type: "success",
      });
    };

    el.addEventListener("click", el.handleEvent);
  },
};
