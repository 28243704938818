<script setup>
import { computed, defineEmits, defineProps, ref, watch } from "vue";
import TreeBlock from "./TreeBlock";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  showCount: {
    type: Boolean,
    default: false,
  },
  height: {
    type: String,
    default: "",
  },
  mobile: {
    type: Boolean,
    default: false,
  },
});
const model = ref([]);

const activeCount = computed(() => {
  return model.value
    .flatMap((item) => {
      if (item.hasOwnProperty("children")) {
        return item.children;
      }
      return [item];
    })
    .filter(({ checked }) => checked).length;
});
const selectedAll = computed(() => {
  return model.value
    .flatMap((item) => {
      if (item.hasOwnProperty("children")) {
        return [item, ...item.children];
      }
      return [item];
    })
    .every(({ checked }) => checked);
});

watch(
  () => props.modelValue,
  () => {
    model.value = JSON.parse(JSON.stringify(props.modelValue));
  },
  {
    immediate: true,
    deep: true,
  }
);

const setCheckedAll = (items, state) => {
  for (const item of items) {
    item.checked = state;

    if (item.hasOwnProperty("children")) {
      setCheckedAll(item.children, state);
    }
  }

  emit("update:modelValue", JSON.parse(JSON.stringify(model.value)));
};

const update = (index, item) => {
  model.value.splice(index, 1, item);
  emit("update:modelValue", JSON.parse(JSON.stringify(model.value)));
};
</script>

<template>
  <b-card
    class="checkbox-block"
    :class="{ desktop: !mobile }"
    no-body
    :style="{ border: mobile ? 'none' : '1px solid rgba(0,0,0,.125)' }"
  >
    <b-card-header class="px-2 py-1 pb-0 d-flex" :class="{ 'px-3 pt-2 pb-2': mobile }">
      <slot name="header">
        <div class="flex-fill">
          <b-form-checkbox :model-value="selectedAll" @change="setCheckedAll(model, !selectedAll)">
            {{ $t("global.all") }}
          </b-form-checkbox>
        </div>
        <div v-if="showCount">{{ activeCount }}</div>
        <div>
          <slot name="after-count" />
        </div>
      </slot>
    </b-card-header>
    <div class="overflow-auto" :style="{ height: mobile ? '100%' : props.height || '185px' }">
      <div class="tree" style="min-width: max-content">
        <ul class="tree-list">
          <slot name="before-list" />
          <tree-block
            v-for="(option, index) in model"
            :key="index"
            @update:modelValue="update(index, $event)"
            :model-value="model[index]"
            class="tree-row"
            v-tooltip="option.tooltip"
          >
            <template #label>
              <slot name="label" v-bind="model[index]"></slot>
            </template>
          </tree-block>
          <slot name="after-list" />
        </ul>
      </div>
    </div>
  </b-card>
</template>

<style lang="scss" scoped>
.checkbox-block.desktop {
  transition: 0.3s all;

  &:hover {
    border: 1px solid var(--primary) !important;
  }
}
</style>
