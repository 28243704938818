const meta = {};

export default [
  //orders
  {
    path: "/ats/orders",
    name: "ats-orders",
    component: () => import(/* webpackChunkName: "ats-orders" */ `@/modules/ats-orders/index.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "ats-orders",
      module: "ats-orders",
    },
  },
  {
    path: "/ats/orders/create",
    name: "ats-orders-create",
    component: () => import(/* webpackChunkName: "ats-orders" */ `@/modules/ats-orders/create.vue`),
    meta: {
      ...meta,
      module: "ats-orders",
      titleLocalizationPath: "ats-orders.create",
    },
  },
  {
    path: "/ats/orders/:id/edit",
    name: "ats-orders-edit",
    component: () => import(/* webpackChunkName: "ats-orders" */ `@/modules/ats-orders/edit.vue`),
    meta: {
      ...meta,
      module: "ats-orders",
      titleLocalizationPath: "ats-orders.edit",
    },
  },

  //user-links
  {
    path: "/ats/user-links",
    name: "ats-user-links",
    component: () => import(/* webpackChunkName: "ats-user-links" */ `@/modules/ats-user-links/index.vue`),
    meta: {
      ...meta,
      module: "ats-user-links",
      titleLocalizationPath: "ats-user-links",
    },
  },
  {
    path: "/ats/user-links/create",
    name: "ats-user-links-create",
    component: () => import(/* webpackChunkName: "ats-user-links" */ `@/modules/ats-user-links/create.vue`),
    meta: {
      ...meta,
      module: "ats-user-links",
      titleLocalizationPath: "ats-user-links.create",
    },
  },
  {
    path: "/ats/user-links/:id/edit",
    name: "ats-user-links-edit",
    component: () => import(/* webpackChunkName: "ats-user-links" */ `@/modules/ats-user-links/edit.vue`),
    meta: {
      ...meta,
      module: "ats-user-links",
      titleLocalizationPath: "ats-user-links.edit",
    },
  },

  //orders-payments
  {
    path: "/ats/orders-payments",
    name: "ats-orders-payments",
    component: () => import(/* webpackChunkName: "ats-orders-payments" */ `@/modules/ats-orders-payments/index.vue`),
    meta: {
      ...meta,
      module: "ats-orders-payments",
      titleLocalizationPath: "ats-orders-payments",
    },
  },

  //sales-report
  {
    path: "/ats/sales-report",
    name: "ats-sales-report",
    component: () => import(/* webpackChunkName: "ats-sales-report" */ `@/modules/ats-sales-report/index.vue`),
    meta: {
      ...meta,
      module: "ats-sales-report",
      titleLocalizationPath: "ats-sales-report",
    },
  },
];
