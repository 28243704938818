import store from "@/store";

const routes = () => {
  const USERS_FOR_REDIRECT = ["agency_manager", "office_manager", "agency_director", "sub_partition_manager"];
  const credentials = store.getters["auth/credentials"];
  const meta = {
    module: "pages",
  };
  const initialRoutes = [
    {
      path: "/",
      component: () => import(/* webpackChunkName: "search-tour" */ `@/modules/search-tour/index.vue`),
      meta: {
        ...meta,
        module: "search-tour",
        titleLocalizationPath: "search-tour",
      },
    },
    {
      path: "/publications",
      name: "pages-news",
      component: () => import(/* webpackChunkName: "pages" */ `@/modules/${meta.module}/publications.vue`),
      meta: {
        ...meta,
        titleLocalizationPath: "pages.news",
      },
    },
    {
      path: "/rules",
      name: "pages-rules",
      component: () => import(/* webpackChunkName: "pages" */ `@/modules/${meta.module}/rules.vue`),
      meta: {
        ...meta,
        titleLocalizationPath: "pages.rules",
      },
    },
  ];

  if (!credentials.user) {
    return [
      {
        path: "/",
        redirect: "/auth/login",
      },
      ...initialRoutes,
    ];
  }

  if (USERS_FOR_REDIRECT.includes(credentials.user.alias)) {
    return [
      {
        path: "/",
        redirect: "/publications",
      },
      ...initialRoutes,
    ];
  }

  return initialRoutes;
};

export default routes();
