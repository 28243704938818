<script setup>
import { computed, defineEmits, defineProps, ref, useAttrs, watch } from "vue";

import { Field } from "vee-validate";
import moment from "moment";
import { useStore } from "vuex";
import { breakpointsBootstrapV5, useBreakpoints } from "@vueuse/core/index";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: String,
    default: () => "",
  },
  startDate: {
    type: String,
    default: "",
  },
  markers: {
    type: Object,
    default: () => ({}),
  },
  allowedDates: {
    type: Array,
    default: () => [],
  },
  name: {
    type: String,
    default: () => "Field" + Math.floor(Math.random() * 100),
  },
  rules: {
    type: String,
    default: () => "",
  },
  iconText: {
    type: String,
    default: () => "",
  },
  icon: {
    type: String,
    default: () => "calendar-alt",
  },
  placement: {
    type: String,
    default: () => "bottom-start",
  },
  preventInputClick: {
    type: Boolean,
    default: () => false,
    required: false,
  },
  mobileReadonly: {
    type: Boolean,
    default: false,
    required: false,
  },
  minDate: {
    type: String,
    default: () => moment().subtract(100, "year").format("YYYY-MM-DD"),
  },
  maxDate: {
    type: String,
    default: () => moment().add(100, "year").format("YYYY-MM-DD"),
  },
});

const model = ref(null);
const store = useStore();
const attrs = useAttrs();

const breakpoints = useBreakpoints(breakpointsBootstrapV5);
const isMobile = breakpoints.smaller("md");

const componentKey = (Math.random() * 10).toFixed();

const disabledDates = computed(() => {
  const dates = [];

  if (props.allowedDates.length) {
    const [firstDay] = props.allowedDates;
    const lastDay = props.allowedDates[props.allowedDates.length - 1];

    const minDate = moment.min(moment(firstDay, "YYYY-MM-DD"), moment(props.minDate, "YYYY-MM-DD"));
    const maxDate = moment.max(moment(lastDay, "YYYY-MM-DD"), moment(props.maxDate, "YYYY-MM-DD"));

    for (let date = minDate; date.isBefore(maxDate); date.add(1, "days")) {
      const currentDate = `${date.format("YYYY-MM-DD")}`;

      if (props.allowedDates.every((el) => el !== currentDate)) {
        dates.push(`${currentDate}T00:00`);
      }
    }
  }

  return dates;
});

const attributes = computed(() => {
  return Object.values(props.markers).map((item) => ({
    highlight: item.color
      ? {
          color: item.color,
          fillMode: "light",
        }
      : undefined,
    dates: `${item.date}T00:00`,
    popover: {
      placement: "top",
      visibility: "hover",
      isInteractive: false,
    },
  }));
});

const initialPage = computed(() => {
  const startDate = model.value || props.startDate || moment().format("YYYY-MM-DD");

  return {
    year: moment(startDate, "YYYY-MM-DD").year(),
    month: moment(startDate, "YYYY-MM-DD").month() + 1,
  };
});

watch(
  () => props.modelValue,
  (value) => {
    if (value) {
      model.value = moment(value, "YYYY-MM-DD").toDate();
    } else {
      model.value = null;
    }
  },
  {
    immediate: true,
  }
);

const update = () => {
  if (model.value) {
    const date = moment(model.value).format("YYYY-MM-DD");

    if (props.modelValue === date) {
      return;
    }

    emit("update:modelValue", date);
  } else {
    emit("update:modelValue", "");
  }
};

const setDate = ({ value }) => {
  if (!value) {
    model.value = null;
    return;
  }

  const [dd, mm, yyyy] = value.split(".");

  if (dd && mm && yyyy) {
    const date = moment(`${yyyy}-${mm}-${dd}`, "YYYY-MM-DD");

    if (date.isValid() && validateInput(date)) {
      model.value = date.toDate();
    } else {
      model.value = "";
      model.value = null;
    }
  } else {
    model.value = "";
    model.value = null;
  }
};

const validateInput = (date) => {
  if (props.allowedDates.length) {
    return props.allowedDates.includes(date.format("YYYY-MM-DD"));
  }

  return date.isSameOrBefore(props.maxDate) && date.isSameOrAfter(props.minDate);
};

const handleInputClick = (e) => {
  if (props.preventInputClick) {
    e.stopPropagation();
  }
};

const toggleCalendar = (callback) => {
  if (!attrs.hasOwnProperty("disabled") || attrs.disabled === false) {
    callback();
  }
};
</script>

<template>
  <Field v-slot="{ meta }" :modelValue="modelValue" :name="name" :rules="rules" @submit.stop.prevent>
    <div class="w-100">
      <v-date-picker
        borderless
        v-model="model"
        :attributes="attributes"
        :locale="store.getters['localization/getCurrent']"
        @update:modelValue="update"
        :min-date="`${props.minDate}T00:00`"
        :max-date="`${props.maxDate}T00:00`"
        :disabled-dates="disabledDates"
        :initial-page="initialPage"
        :first-day-of-week="2"
        :popover="{
          visibility: 'click',
          placement: props.placement,
          positionFixed: true,
        }"
      >
        <template #footer>
          <div class="p-2 pt-0" :id="`datepicker-${componentKey}`" style="max-width: 250px"></div>
        </template>
        <template
          #day-popover="{ day }"
          v-if="!isMobile && Object.values(props.markers).some(({ tooltip }) => tooltip)"
        >
          <teleport :to="`#datepicker-${componentKey}`">
            <div :key="index" v-for="(tooltip, index) in props.markers[day.id].tooltip">
              <div class="divider-element mb-2"></div>
              <div class="py-2 px-1 d-flex align-items-start">{{ tooltip.text }}</div>
            </div>
          </teleport>
        </template>
        <template #default="{ togglePopover }">
          <div
            @click="toggleCalendar(togglePopover)"
            :class="{
              'input-icon': true,
              'input-icon-with-text': !!iconText,
              'input-prepend-icon': true,
              'w-100': true,
              disabled: $attrs.disabled,
            }"
          >
            <template v-if="icon">
              <div class="inline-icon d-flex align-items-center justify-content-center">
                <ui-icon :name="icon" size="17" />
              </div>
            </template>
            <span class="icon-text">{{ iconText }}</span>
            <div @click="handleInputClick" class="position-relative input" :class="{ 'enable-hover': !isMobile }">
              <input
                v-maska="'##.##.####'"
                :class="{ danger: meta.validated && !meta.valid }"
                :value="model ? moment(model).format('DD.MM.YYYY') : ''"
                class="form-control"
                :id="`${props.name}-datepicker`"
                type="text"
                v-bind="$attrs"
                :readonly="mobileReadonly && isMobile"
                @blur="setDate($event.target)"
              />
              <div
                class="clear-icon text-center"
                :class="{
                  'disabled-bg': $attrs.disabled === 'true' || $attrs.disabled === true || $attrs.disabled === '',
                }"
              >
                <ui-icon @click.stop="model = null" v-if="model" name="times" size="18" color="var(--gray-3)"></ui-icon>
              </div>
            </div>
          </div>
        </template>
      </v-date-picker>
    </div>
  </Field>
</template>

<style lang="scss" scoped>
.inline-icon {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 35px;
  color: var(--gray-3);
  transform: none;
  height: 100%;

  svg {
    transform: translateX(2px);
  }
}

.fa-icon:hover {
  -webkit-box-shadow: 1px 1px 0px 6px var(--blue-light);
  -moz-box-shadow: 1px 1px 0px 6px var(--blue-light);
  box-shadow: 1px 1px 0px 6px var(--blue-light);
  border-radius: 2px;
  cursor: pointer;
}

.input {
  .clear-icon {
    position: absolute;
    top: 50%;
    right: 1px;
    width: 32px;
    transform: translateY(-50%);
    cursor: pointer;
    display: block;
    z-index: 10;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 30%);

    &.disabled-bg {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--gray-6) 30%);
    }
  }
}

.input.enable-hover {
  .clear-icon {
    display: none;
  }
}

.input.enable-hover:hover {
  .clear-icon {
    display: block !important;
  }
}
</style>
