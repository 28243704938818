<script setup>
import { computed, defineEmits, defineProps, nextTick, ref, useAttrs, watch } from "vue";
import moment from "moment";
import { useStore } from "vuex";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: String,
    default: () => "",
  },
  startDate: {
    type: String,
    default: "",
  },
  markers: {
    type: Object,
    default: () => ({}),
  },
  allowedDates: {
    type: Array,
    default: () => [],
  },
  name: {
    type: String,
    default: () => "Field" + Math.floor(Math.random() * 100),
  },
  rules: {
    type: String,
    default: () => "",
  },
  iconText: {
    type: String,
    default: () => "",
  },
  format: {
    type: String,
    default: () => "YYYY-MM-DD",
  },
  icon: {
    type: String,
    default: () => "calendar-alt",
  },
  preventInputClick: {
    type: Boolean,
    default: () => false,
    required: false,
  },
  minDate: {
    type: String,
    default: () => moment().subtract(100, "year").format("YYYY-MM-DD"),
  },
  maxDate: {
    type: String,
    default: () => moment().add(5, "year").format("YYYY-MM-DD"),
  },
});

const model = ref(null);
const input = ref(null);
const store = useStore();
const attrs = useAttrs();

const componentKey = (Math.random() * 10).toFixed();

const disabledDates = computed(() => {
  const dates = [];
  const min = props.minDate;
  const max = props.maxDate;

  if (props.allowedDates.length) {
    const [firstDay] = props.allowedDates;
    const lastDay = props.allowedDates[props.allowedDates.length - 1];

    const minDate = moment.min(moment(firstDay), moment(min));
    const maxDate = moment.max(moment(lastDay), moment(max));

    for (let date = moment(minDate); date.isBefore(maxDate); date.add(1, "days")) {
      const currentDate = date.format("YYYY-MM-DD");

      if (props.allowedDates.every((el) => el !== currentDate)) {
        dates.push(currentDate);
      }
    }
  }

  return dates;
});

const attributes = computed(() => {
  return Object.values(props.markers).map((item) => ({
    highlight: item.color
      ? {
          color: item.color,
          fillMode: "light",
        }
      : undefined,
    dates: item.date,
    popover: {
      placement: "top",
      visibility: "hover",
      isInteractive: false,
    },
  }));
});

const initialPage = computed(() => {
  const startDate = model.value || props.startDate || props.minDate || moment().format("YYYY-MM-DD");

  return {
    year: moment(startDate, "YYYY-MM-DD").year(),
    month: moment(startDate, "YYYY-MM-DD").month() + 1,
  };
});

watch(
  () => props.minDate && props.maxDate,
  () => {
    checkModelValue();
  }
);

watch(
  () => props.modelValue,
  (value) => {
    if (value) {
      if (moment(value, props.format).isValid()) {
        model.value = moment(value, props.format).format("YYYY-MM-DD");
      } else if (moment(value, "YYYY-MM-DD").isValid()) {
        model.value = moment(value, "YYYY-MM-DD").format("YYYY-MM-DD");
      }
    } else {
      model.value = null;
    }

    nextTick(() => {
      input.value.focus();

      checkModelValue();

      nextTick(() => {
        input.value.blur();
      });
    });
  },
  {
    immediate: true,
  }
);

const checkModelValue = () => {
  if (!!model.value) {
    const date = moment(model.value, "YYYY-MM-DD", true);

    if (!(date.isSameOrBefore(props.maxDate) && date.isSameOrAfter(props.minDate))) {
      model.value = null;
    }
  }
};

const update = () => {
  if (model.value) {
    const date = moment(model.value, "YYYY-MM-DD").format(props.format);

    if (props.modelValue === date) {
      return;
    }

    emit("update:modelValue", moment(model.value, "YYYY-MM-DD").format(props.format));
  } else {
    emit("update:modelValue", "");
  }
};

const setDate = ({ value }) => {
  if (!value) {
    model.value = null;
    return;
  }

  const [dd, mm, yyyy] = value.split(".");

  if (dd && mm && yyyy) {
    const date = moment(`${yyyy}-${mm}-${dd}`, "YYYY-MM-DD", true);

    if (date.isValid() && validateInput(date)) {
      model.value = date.format("YYYY-MM-DD");
    } else {
      model.value = "";
      model.value = null;
    }
  } else {
    model.value = "";
    model.value = null;
  }
};

const validateInput = (date) => {
  if (props.allowedDates.length) {
    return props.allowedDates.includes(date.format("YYYY-MM-DD"));
  }

  return date.isSameOrBefore(props.maxDate) && date.isSameOrAfter(props.minDate);
};

const toggleCalendar = (callback) => {
  if (!attrs.hasOwnProperty("disabled") || attrs.disabled === false) {
    callback();
  }
};
</script>

<template>
  <v-date-picker
    borderless
    v-model="model"
    :attributes="attributes"
    :locale="store.getters['localization/getCurrent']"
    @update:modelValue="update"
    :min-date="props.minDate"
    :max-date="props.maxDate"
    :disabled-dates="disabledDates"
    :initial-page="initialPage"
    :popover="{
      visibility: 'click',
      placement: 'bottom-start',
    }"
  >
    <template #footer>
      <div class="p-2 pt-0" :id="`datepicker-${componentKey}`" style="max-width: 250px"></div>
    </template>
    <template #day-popover="{ day }">
      <teleport :to="`#datepicker-${componentKey}`">
        <div class="divider-element mb-2"></div>
        <div
          :key="index"
          class="py-2 px-1 d-flex align-items-start"
          v-for="(tooltip, index) in props.markers[moment(day.date).format('YYYY-MM-DD')].tooltip"
        >
          <div>{{ tooltip.text }}</div>
        </div>
      </teleport>
    </template>
    <template #default="{ togglePopover }">
      <div @click="toggleCalendar(togglePopover)" class="h-100">
        <input
          v-maska="'##.##.####'"
          class="h-100"
          @paste.stop
          :value="model ? moment(model).format('DD.MM.YYYY') : ''"
          type="text"
          v-bind="$attrs"
          ref="input"
          @blur="setDate($event.target)"
        />
      </div>
    </template>
  </v-date-picker>
</template>

<style lang="scss" scoped>
input {
  border: 0;
}
</style>
