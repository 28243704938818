<script setup>
import { defineProps, onMounted, ref } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});

const image = ref("");

onMounted(() => {
  try {
    image.value = require(`@/assets/images/air_logos/${props.name}.png`);
  } catch (err) {
    image.value = require(`@/assets/images/no-image.jpg`);
  }
});
</script>

<template>
  <img :src="image" alt="" class="rounded-circle" v-bind="$attrs" />
</template>
