import ApiModel from "../APIModel";
import { AxiosResponse } from "axios";

export default class Offers extends ApiModel {
  constructor() {
    super("v1/rules");
  }

  categories(): Promise<AxiosResponse> {
    return this.http.ignoreErrorHandler().getAxiosInstance().get(`${this.resourceUrl}/categories`);
  }
}
