const meta = {
  module: "faq",
};

const routes = [
  {
    path: "/faq",
    name: "faq",
    component: () => import(/* webpackChunkName: "faq" */ `@/modules/${meta.module}/index.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "faq",
    },
  },
];

export default routes;
