<script setup>
import { nextTick, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import API from "@/services/api/API";

const store = useStore();
const router = useRouter();

const showDropdown = ref(false);
const dropdown = ref(null);
const model = ref(null);
const pagination = ref({
  size: 10,
  number: 1,
});

const closeOnBlur = (e) => {
  if (!dropdown.value?.contains(e.relatedTarget)) {
    close();
  }
};

const open = async () => {
  try {
    const { data } = await API.notifications().get({
      page: pagination.value,
    });

    model.value = data.map((item) => {
      const div = document.createElement("div");

      div.innerHTML = item.message.replaceAll("&nbsp;", " ");

      let message = div.textContent;

      if (message.length > 80) {
        message = `${message.trim().slice(0, 80)}...`;
      }

      return {
        ...item,
        message,
      };
    });

    showDropdown.value = true;

    await nextTick(() => {
      dropdown.value?.focus();
    });
  } catch (error) {
    await store.dispatch("alert/show", {
      title: error.error,
      text: error.message,
      type: "error",
    });
  }
};

const close = () => {
  showDropdown.value = false;
};

const openNotification = async ({ id, was_read }) => {
  try {
    if (!was_read) {
      await API.notifications().read(id);
    }

    await router.push(`/notifications/all/${id}`);
    close();
  } catch (error) {
    await store.dispatch("alert/show", {
      title: error.error,
      text: error.message,
      type: "error",
    });
  }
};
</script>

<template>
  <div class="notification">
    <ui-icon class="cursor-pointer" color="var(--bs-white)" name="envelope" size="24" @click="open()"></ui-icon>
    <Transition name="fade">
      <div
        v-if="showDropdown"
        id="dropdown"
        ref="dropdown"
        class="notification__dropdown"
        tabindex="-1"
        @blur="closeOnBlur"
      >
        <template v-if="model.length">
          <div class="overflow-auto">
            <template v-for="(item, index) in model" :key="index">
              <div
                :class="{ 'was-read': item.was_read }"
                class="p-3 text-gray-1 notification__row"
                @click="openNotification(item)"
              >
                <div class="fs-14px fw-bold">
                  {{ item.subject }}
                </div>
                <div class="mt-2 fs-14px">
                  {{ item.message }}
                </div>
                <div class="fs-12px mt-1 text-end">
                  {{ item.created_at }}
                </div>
              </div>
              <div class="divider"></div>
            </template>
          </div>
          <router-link
            class="py-2 text-center px-3 text-primary fw-medium d-block show-all-button"
            to="/notifications/all"
            @click="close()"
          >
            {{ $t("global.show_all") }}
          </router-link>
        </template>
        <div v-else class="p-3 text-gray-3 text-center">
          {{ $t("global.messages.empty_list") }}
        </div>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.notification {
  position: relative;

  & * {
    line-height: 1.2;
  }

  &__row:hover {
    background: var(--primary-light);
    cursor: pointer;
  }

  &__row:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &__dropdown {
    position: fixed;
    width: 441px;
    top: 54px;
    right: 40px;
    outline: none;
    background: var(--bs-white);
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    letter-spacing: -0.154px;
  }
}

.was-read {
  background: #fafafa;

  & > div {
    color: var(--gray-3) !important;
  }
}

.divider {
  height: 1px;
  background: var(--gray-5);
}

@include media-breakpoint-down(md) {
  .notification {
    &__dropdown {
      position: fixed;
      width: 100vw;
      max-height: 60vh;
      overflow: auto;
      top: 52px;
      right: 0;
      outline: none;
      background: var(--bs-white);
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      letter-spacing: -0.154px;
    }
  }
}

.show-all-button {
  position: sticky;
  bottom: 0;
  background: var(--bs-white);
}
</style>
