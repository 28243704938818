<script setup>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import debounce from "lodash/fp/debounce";

const debounced = ref(null);
const store = useStore();

const show = ref(false);

watch(
  () => store.getters["preloader/show"],
  (value) => {
    if (!show.value && value) {
      show.value = value;
    }

    if (debounced.value) {
      debounced.value.cancel();
    }
    debounced.value = debounce(250, () => {
      show.value = value;
    });
    debounced.value();
  }
);
</script>

<template>
  <div class="progress" v-if="show">
    <div class="progress-bar">
      <div class="progress-bar-value"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.progress {
  position: fixed;
  z-index: 999998;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  width: 100%;
}

.progress-bar {
  height: 4px;
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: var(--primary-push);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  100% {
    transform: translateX(0) scaleX(1);
  }
}
</style>
