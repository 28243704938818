<script setup>
import defaultLayout from "./layouts/Default.vue";
import emptyLayout from "./layouts/Empty.vue";
import { computed, watch } from "vue";
import { useRoute } from "vue-router";
import Alert from "@/components/dialogs/Alert.vue";
import Preloader from "@/components/dialogs/Preloader.vue";
import PageLoader from "@/components/dialogs/PageLoader.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const appTitle = computed(() => store.getters["app/title"]);

watch(
  () => store.getters["app/favicon"],
  (val) => {
    const favicon = parent.document.querySelector("link[rel=icon]");

    favicon.href = val;
  },
  {
    immediate: true,
  }
);

watch(
  () => route.name || appTitle.value,
  () => {
    const title = t(`${route.meta.titleLocalizationPath}.title`);

    if (title && (!title.includes(".") || title.includes(" "))) {
      document.title = `${appTitle.value} - ${title}`;
    } else {
      document.title = appTitle.value;
    }
  },
  {
    immediate: true,
  }
);

const layout = computed(() => {
  const layoutName = route.meta?.layout || "Default";

  switch (layoutName) {
    case "Empty":
      return emptyLayout;
    default:
      return defaultLayout;
  }
});

const showGlobalLoader = computed(() => store.getters["preloader/showGlobal"]);
</script>

<template>
  <preloader></preloader>
  <div>
    <alert></alert>
    <transition mode="out-in" name="zoom-fade">
      <page-loader v-if="showGlobalLoader"></page-loader>
    </transition>
    <transition mode="out-in" name="zoom-fade">
      <component :is="layout" v-if="Object.keys(route.meta).length"></component>
    </transition>
  </div>
</template>
