<script setup>
import UiIcon from "@/components/global/UiIcon";
import ExpandTransition from "@/plugins/expand-menu-transition";
import { computed, defineProps, watch } from "vue";
import { useStore } from "vuex";
import MenuLinkWrapper from "@/components/navigation/aside/MenuLinkWrapper";

const props = defineProps({
  list: {
    type: Array,
    required: true,
  },
  deep: {
    type: Number,
    required: false,
    default: 1,
  },
  searchString: {
    type: String,
    required: false,
    default: "",
  },
});

const store = useStore();

watch(
  () => props.searchString,
  (value) => {
    if (value) {
      openAll(props.list);
    } else {
      closeAllOpened(props.list);
    }
  }
);

const calculateDeep = computed(() => {
  if ((store.getters["navigation/show"] || store.getters["navigation/showMobile"]) && props.deep > 1) {
    return 4 + 12 * props.deep;
  }

  return 16;
});

const closeAllOpened = (list) => {
  for (const listElement of list) {
    if (listElement.hasOwnProperty("children")) {
      listElement.open = false;
      closeAllOpened(listElement.children);
    }
  }
};

const openAll = (list) => {
  for (const listElement of list) {
    if (listElement.hasOwnProperty("children")) {
      listElement.open = true;
    }
  }
};

const toggleChildren = (item) => {
  const open = !item.open;

  closeAllOpened(props.list);

  if (item.hasOwnProperty("children")) {
    item.open = open;
  }
};

const getRowClass = (row) => {
  if (row.open) {
    return `menu-background-row-${props.deep + 1}`;
  }

  return `menu-background-row-${props.deep}`;
};
</script>

<template>
  <div class="menu">
    <menu-link-wrapper v-for="item in props.list" :key="item.id" :item="item">
      <div v-if="JSON.stringify(item).toLowerCase().includes(props.searchString.toLowerCase())">
        <div
          :class="getRowClass(item)"
          :style="{
            paddingLeft: `${calculateDeep}px`,
          }"
          class="d-flex align-center pe-2 menu__row cursor-pointer justify-content-between icon-transition"
          @click="toggleChildren(item)"
        >
          <div class="d-flex align-items-center w-100">
            <div class="text-center w-18px">
              <i class="fa" :class="item.icon"></i>
            </div>
            <div class="ps-3" :style="{ width: `${deep > 1 ? 187 - deep * 12 : 187}px` }">{{ item.text }}</div>
          </div>
          <div class="d-flex align-items-center pe-1 w-18px">
            <ui-icon :name="item.open ? 'angle-up' : 'angle-down'" size="18" v-if="item.children"></ui-icon>
          </div>
        </div>
        <div class="custom-divider"></div>
        <transition name="expand-transition" mode="in-out" v-on="ExpandTransition()">
          <div v-if="item.children && item.open" class="default-transition">
            <menu-list :deep="props.deep + 1" :list="item.children" :search-string="props.searchString"></menu-list>
          </div>
        </transition>
      </div>
    </menu-link-wrapper>
  </div>
</template>

<style lang="scss" scoped>
.menu {
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;

  &__row {
    width: 280px;
    height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;

    & > div {
      transition: 0.5s all;
    }
  }

  .menu__row:hover > div {
    padding-left: 2px !important;
  }

  .menu__row:hover {
    background: var(--primary);
  }
}

.icon-transition {
  transition: 0.3s all;
}

.default-transition {
  transition: 0.5s all;
}

.menu-background-row-1 {
  background: #222d32;
}

.menu-background-row-2 {
  background: #2b3236;
}

.menu-background-row-3 {
  background: #394146;
}

.menu-background-row-4 {
  background: #49545b;
}

.w-18px {
  min-width: 18px;
}
</style>
