class CacheService {
  static async clearRouteCache(name: string) {
    try {
      const caches = await window.caches.open(name);
      const keys = await caches.keys();

      for await (const cache of keys) {
        await caches.delete(cache);
      }
    } catch {
      await Promise.resolve();
    }
  }
}

export default CacheService;
