//import notificationAudio from "@/assets/sounds/notification.mp3";

import { ActionContext } from "vuex";
import store from "@/store";

interface MessageInterface {
  title: string;
  text: string;
  index?: number;
  icon?: string;
  duration?: string;
  withoutClosing?: boolean;
  enableSound?: boolean;
  onClick?: () => void;
  list?: string[];
}

interface StateInterface {
  show: boolean;
  timeout: number;
  messages: MessageInterface[];
}

const showCount = (state: StateInterface) => {
  const faviconSize = 30;
  const count = state.messages.length;
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const img = document.createElement("img");
  const favicon: any = parent.document.querySelector("link[rel=icon]");

  img.src = store.getters["app/favicon"];

  canvas.width = faviconSize;
  canvas.height = faviconSize;

  img.onload = (): void => {
    if (context === null) {
      return;
    }

    context.drawImage(img, 0, 0, faviconSize, faviconSize);

    if (count > 0) {
      context.beginPath();
      context.arc(canvas.width - faviconSize / 3, faviconSize / 3, faviconSize / 3, 0, 2 * Math.PI);
      context.fillStyle = "#B41F16";
      context.fill();
      context.font = '12px "helvetica", sans-serif';
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillStyle = "#FFFFFF";
      context.fillText(String(count), canvas.width - faviconSize / 3, faviconSize / 3);

      favicon.href = canvas.toDataURL("image/png");
    } else {
      favicon.href = store.getters["app/favicon"];
    }
  };
};

export default {
  namespaced: true,

  state: {
    show: false,
    timeout: 3500,
    messages: [],
  } as StateInterface,

  mutations: {
    async show(state: StateInterface, payload: MessageInterface) {
      if (!payload.text) {
        return;
      }

      let index = state.messages.length;

      if (index) {
        index = (state.messages[index - 1].index as number) + 1;
      }

      state.messages.push({ ...payload, index });

      if (!payload.hasOwnProperty("withoutClosing")) {
        setTimeout(() => {
          state.messages.splice(
            state.messages.findIndex((item: any) => item.index === index),
            1
          );
          showCount(state);
        }, state.timeout);
      }

      // if (payload.hasOwnProperty("enableSound")) {
      //   const player = new Audio(notificationAudio);
      //   await player.play();
      // }

      state.show = true;
    },
    hide(state: StateInterface, index: number) {
      state.messages.splice(index, 1);
    },
    hideAll(state: StateInterface) {
      state.show = false;
    },
    showCount,
  },

  actions: {
    show({ commit }: ActionContext<StateInterface, "{}">, payload: MessageInterface) {
      commit("show", { ...payload });
      commit("showCount");
    },
    hide({ commit }: ActionContext<StateInterface, "{}">, index: StateInterface) {
      commit("hide", index);
      commit("showCount");
    },
    hideAll({ commit }: ActionContext<StateInterface, "{}">) {
      commit("hide");
      commit("showCount");
    },
  },

  getters: {
    show: (state: StateInterface) => state.show,
    messages: (state: StateInterface) => state.messages,
  },
};
