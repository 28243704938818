<script>
export default {
  name: "DefaultLayout",
};
</script>

<script setup>
import Navbar from "../components/navigation/Navbar.vue";
import LeftNav from "../components/navigation/aside/Aside.vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { onMounted } from "vue";

const store = useStore();
const router = useRouter();
const route = useRoute();

router.beforeEach((to, from, next) => {
  store.dispatch("navigation/setMobileMenu", false);
  store.dispatch("navigation/setMenu", false);
  next();
});

onMounted(() => {
  store.dispatch("navigation/setMenu", false);
});
</script>

<template>
  <div
    :class="{ open: store.getters['navigation/show'], 'mobile-open': store.getters['navigation/showMobile'] }"
    class="general-menu"
  >
    <div class="overlay-menu" @click="store.dispatch('navigation/toggleMobileMenu')"></div>
    <navbar />
    <leftNav />
    <div class="content">
      <router-view v-slot="{ Component }">
        <transition mode="out-in" name="zoom-fade">
          <component :is="Component" :id="`${route.name}__container`" />
        </transition>
      </router-view>
    </div>
  </div>
</template>
