import API from "@/services/api/API";
import { Store } from "vuex";
import store from "@/store";

class NavigationService {
  private store: Store<unknown>;

  constructor() {
    this.store = store;
  }

  public async load() {
    const response = await API.core().navigation();
    await this.store.dispatch("navigation/setMenuItems", response);
  }
}

export default NavigationService;
