import ApiModel from "../APIModel";

export default class PaymentGateways extends ApiModel {
  constructor() {
    super("payment/gateways");
  }

  patch(id: number | string, payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(422).patch(`${this.resourceUrl}/${id}/update`, payload);
  }

  delete(id: number): Promise<any> {
    return this.http.delete(`${this.resourceUrl}/${id}/delete`);
  }
}
