import { ALLOWED_DOMAINS, DEFAULT_PATH } from "@/themes";
import { Store } from "vuex";
import store from "@/store";

class ThemeService {
  private readonly allowedDomains: { [value: string]: string };
  private readonly defaultPath: string;
  private readonly domain: string;
  private firstLoad: boolean;
  private store: Store<unknown>;

  constructor() {
    this.store = store;
    this.firstLoad = false;
    this.allowedDomains = ALLOWED_DOMAINS;
    this.defaultPath = DEFAULT_PATH;
    this.domain = location.host;
  }

  public async load() {
    if (!this.firstLoad) {
      await this.loadStyles();
      await this.loadInfo();
      await this.loadAssets();

      this.firstLoad = true;
    }
  }

  private async loadInfo() {
    try {
      const { default: info } = await import(
        `@/themes/${this.allowedDomains[this.domain] || this.defaultPath}/info.json`
      );

      await this.store.dispatch("app/set", { ...info });
    } catch {
      await Promise.resolve();
    }
  }

  private async loadAssets() {
    try {
      const path = `themes/${this.allowedDomains[this.domain] || this.defaultPath}/assets`;

      const { default: logo } = await import(`@/${path}/logo.png`);
      const { default: favicon } = await import(`@/${path}/favicon.png`);
      const { default: searchIsland } = await import(`@/${path}/search-island.png`);

      await this.store.dispatch("app/set", { logo, favicon, searchIsland });
    } catch {
      await Promise.resolve();
    }
  }

  private async loadStyles() {
    try {
      await import(`@/themes/${this.allowedDomains[this.domain]}/styles.scss`);
    } catch {
      await import(`@/themes/${this.defaultPath}/styles.scss`);
    }
  }
}

export default ThemeService;
