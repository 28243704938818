<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const show = computed(() => {
  return store.getters["alert/show"];
});

const messages = computed(() => {
  return store.getters["alert/messages"];
});

const hide = async (message, index) => {
  try {
    if (message.hasOwnProperty("onClickClose")) {
      await message.onClickClose();
    }

    await store.dispatch("alert/hide", index);
  } catch (e) {
    await store.dispatch("alert/show", {
      type: "error",
      title: "",
      text: e.message,
    });
  }
};

const onClick = async (message, index) => {
  if (message.onClick) {
    await message.onClick();
  }
  await hide(message, index);
};

const position = computed(() => {
  const user = store.getters["auth/user"];

  if (user) {
    return user.notification_position
      .split("-")
      .map((item) => `position__${item}`)
      .join(" ");
  }

  return `position__bottom position__right`;
});
</script>

<template>
  <div :class="position" class="alert-container">
    <TransitionGroup name="fade" tag="div">
      <template v-for="(message, index) in messages" :key="message.index">
        <div
          v-if="!message.modal"
          :class="{ 'mb-2': index + 1 < messages.length, 'cursor-pointer': message.hasOwnProperty('onClick') }"
          class="list-complete-item"
          @click="onClick(message, index)"
        >
          <div v-if="message.modal" class="modal-alert__overlay" @click.stop></div>
          <div
            :class="{
              [message.type]: message.type,
              'text-black': !message.type,
            }"
            class="custom-alert"
          >
            <div class="custom-alert__header ps-4 pt-3 pb-3 pe-3 d-flex justify-content-between align-items-start">
              <div class="fs-20px fw-bold pe-4">{{ message.title }}</div>
              <div>
                <ui-icon
                  class="cursor-pointer"
                  :color="message.type ? 'var(--bs-white)' : 'var(--gray-2)'"
                  name="multiply"
                  size="23px"
                  @click.stop="hide(message, index)"
                ></ui-icon>
              </div>
            </div>
            <div class="custom-alert__body p-4 pt-2 fs-16px fw-medium">
              <div v-html="message.text"></div>
              <div v-if="message.author" class="fs-12px text-end mt-2 fw-medium">{{ message.author }}</div>
            </div>
          </div>
        </div>
      </template>
    </TransitionGroup>
    <div v-if="!!messages.find((item) => item.modal)">
      <div class="modal-overlay d-flex align-items-center justify-content-center" @click.stop>
        <TransitionGroup class="modal-container" name="modal-fade" tag="div">
          <template v-for="(message, index) in messages" :key="message.index">
            <div
              v-if="message.modal"
              :class="{
                'mb-2': index + 1 < messages.length,
                [message.type]: message.type,
                'text-black': !message.type,
                'full-screen': message.fullScreen,
              }"
              class="list-complete-item custom-alert modal-alert pb-4"
            >
              <div class="custom-alert__header ps-4 pt-3 pb-3 pe-3 d-flex justify-content-between align-items-start">
                <div class="fs-20px fw-bold pe-4">{{ message.title }}</div>
                <div>
                  <ui-icon
                    class="cursor-pointer"
                    :color="message.type ? 'var(--bs-white)' : 'var(--gray-2)'"
                    name="multiply"
                    size="23px"
                    @click.stop="hide(message, index)"
                  ></ui-icon>
                </div>
              </div>
              <div class="custom-alert__body px-4 pb-md-5 pt-2 fs-16px fw-medium">
                <div v-html="message.text.replaceAll('&nbsp;', '')"></div>
                <div v-if="message.author" class="fs-12px text-end mt-2 fw-medium">{{ message.author }}</div>
              </div>
            </div>
          </template>
        </TransitionGroup>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.position {
  &__top {
    top: 65px;
  }

  &__left {
    left: 16px;
  }

  &__right {
    right: 16px;
  }

  &__bottom {
    bottom: 16px;
  }

  &__center {
    top: 65px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.alert-container {
  position: fixed;
  z-index: 9999999;

  //&:deep(table) {
  //  .thead,
  //  tbody,
  //  tfoot,
  //  tr,
  //  td,
  //  th {
  //    border-color: inherit;
  //    border-style: solid;
  //    border-width: 1px;
  //  }
  //}
}

.custom-alert {
  box-shadow: 0px 14px 44px rgba(12, 100, 42, 0.1);
  color: var(--bs-white);
  border-radius: 8px;
  position: relative;
  width: 400px;
  max-width: calc(100vw - 32px);
  display: flex;
  flex-direction: column;

  &__header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    flex: 0 1 auto;
  }

  &__body {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    flex: 1 1 auto;
    overflow: auto;
    height: 100%;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}

.modal-container {
  width: 1024px;
  position: relative;
  background: transparent;
  height: 800px;
  max-height: 100vh;

  & > div:not(.full-screen) {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > .full-screen {
    position: fixed;
    left: 0;
    top: 0;
    border-radius: 0;
    width: 100%;
    height: 100%;
    max-width: inherit !important;
  }
}

.modal-alert {
  box-shadow: 0px 14px 44px rgb(0 0 0 / 15%);

  .custom-alert__body {
    padding-bottom: 140px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.alert-warning,
.warning {
  .custom-alert {
    &__header {
      background: #d85b00;
    }

    &__body {
      background: #ee7200;
    }
  }
}

.custom-alert {
  background: white;
}

.alert-danger,
.danger,
.error {
  .custom-alert {
    &__header {
      background: var(--red-deep);
    }

    &__body {
      background: var(--red);
    }
  }
}

.alert-success,
.success {
  .custom-alert {
    &__header {
      background: #219653;
    }

    &__body {
      background: linear-gradient(264.91deg, #219653 -51.04%, #00b191 151.07%);
    }
  }
}

.alert-info,
.info {
  .custom-alert {
    &__header {
      background: #1043c5;
    }

    &__body {
      background: var(--primary);
    }
  }
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(50px);
}

.fade-leave-active {
  position: absolute;
}

.modal-fade-move,
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
  transform: translate(50px, 50%);
}

@include media-breakpoint-down(lg) {
  .modal-container {
    width: 100vw;
    max-width: initial;
    position: relative;
    background: transparent;
    height: 100vh;

    & > div {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .modal-alert {
    width: 100vw !important;
    max-width: initial;
  }
}
</style>
