// import store from "@/store/index";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { HttpInterface } from "@/services/api/http";
import ErrorHandler from "@/services/api/http/error-handler";
import store from "@/store";

class Interceptor {
  public static request(httpInstance: HttpInterface): void {
    httpInstance.getAxiosInstance().interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        const queryConfig: AxiosRequestConfig = config;
        const credentials: any = store.getters["auth/credentials"];

        if (!httpInstance.getPreloaderState()) {
          await store.dispatch("preloader/show");
        }

        if (queryConfig.headers) {
          if (credentials.access_token) {
            queryConfig.headers.Authorization = `Bearer ${credentials.access_token}`;
          }

          queryConfig.headers["x-localization"] = store.getters["localization/getCurrent"];
        }

        return queryConfig;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );
  }

  public static response(httpInstance: HttpInterface): void {
    httpInstance.getAxiosInstance().interceptors.response.use(
      async (response: AxiosResponse) => {
        await store.dispatch("preloader/hide");
        return response;
      },
      async (error: AxiosError) => {
        await store.dispatch("preloader/hide");

        const newResponse = await ErrorHandler.handle(httpInstance.getIgnoredErrors(), error);
        const globalHandlerIgnored = ErrorHandler.globalHandlerIgnored(httpInstance.getIgnoredErrors(), error);

        if (newResponse) {
          return newResponse;
        }

        if (error.response && error.response.status >= 400) {
          if (error.response && typeof error.response.data === "string") {
            error.response.data = {
              message: error.response.data,
            };
          }

          error.response.data = {
            globalHandlerIgnored,
            ...error.response.data,
          };

          return Promise.reject(error.response);
        }
        return Promise.resolve(error.response);
      }
    );
  }
}

export default Interceptor;
