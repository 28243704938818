import ApiModel from "../APIModel";

export default class Subpartitions extends ApiModel {
  constructor() {
    super("subpartitions");
  }

  filters(params = {}): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/filters`, params);
  }

  update(id: number, payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(422).patch(`${this.resourceUrl}/${id}/edit`, payload);
  }
}
