const meta = {
  module: "search-flight",
};

export default [
  {
    path: "/search/flight",
    name: "search-flight",
    component: () => import(/* webpackChunkName: "search-flight" */ `@/modules/${meta.module}/index.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "search-flight",
    },
  },
];
