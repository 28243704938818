const meta = {
  module: "subpartitions",
};

export default [
  {
    path: "/subpartitions",
    name: "subpartitions",
    component: () => import(/* webpackChunkName: "subpartitions" */ `@/modules/${meta.module}/index.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "subpartitions",
    },
  },
  {
    path: "/subpartitions/tourist-list/:id",
    name: "subpartitions-tourists",
    component: () => import(/* webpackChunkName: "subpartitions" */ `@/modules/${meta.module}/tourists.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "subpartitions.tourists",
    },
  },
  {
    path: "/subpartitions/edit/:id",
    name: "subpartitions-edit",
    component: () => import(/* webpackChunkName: "subpartitions" */ `@/modules/${meta.module}/management.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "subpartitions.edit",
    },
  },
];
