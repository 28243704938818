import { configure, defineRule } from "vee-validate";
import rules from "@vee-validate/rules";
import { localize, setLocale } from "@vee-validate/i18n";
import ru from "@vee-validate/i18n/dist/locale/ru.json";
import en from "@vee-validate/i18n/dist/locale/en.json";
import ro from "@vee-validate/i18n/dist/locale/ro.json";
import store from "@/store";
import moment from "@/plugins/moment";
import i18n from "@/plugins/i18n";

const removeFieldName = (module: object) =>
  JSON.parse(JSON.stringify(module).replaceAll(" {field}", "").replace("excluded", "not_one_of"));

configure({
  generateMessage: localize({
    ru: removeFieldName(ru),
    en: removeFieldName(en),
    ro: removeFieldName(ro),
  }),
});

defineRule("minDate", (value: string, [min]: string[]) => {
  if (!value || !value.length) {
    return true;
  }

  const [dd, mm, yyyy] = value.split(".");
  const date = moment(`${yyyy}-${mm}-${dd}`, "YYYY-MM-DD");

  if (date.toDate() < moment(min).toDate()) {
    return i18n.global.t("global.rules.min_date", {
      date: moment(min).format("DD.MM.YYYY"),
    });
  }

  return true;
});

defineRule("decimal", (value: string, { decimals = "*", separator1 = ".", separator2 = "," } = {}) => {
  const errorMessage = i18n.global.t("global.rules.decimal");

  if (value === null || value === undefined || value === "") {
    return errorMessage;
  }
  if (Number(decimals) === 0) {
    return /^-?\d*$/.test(value) || errorMessage;
  }
  const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`;
  const regex = new RegExp(`^[-+]?\\d*(\\${separator1}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);
  const regex2 = new RegExp(`^[-+]?\\d*(\\${separator2}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

  return regex.test(value) || regex2.test(value) || errorMessage;
});

defineRule("maxDate", (value: string, [max]: string[]) => {
  if (!value || !value.length) {
    return true;
  }

  const [dd, mm, yyyy] = value.split(".");
  const date = moment(`${yyyy}-${mm}-${dd}`, "YYYY-MM-DD");

  if (date.toDate() > moment(max).toDate()) {
    return i18n.global.t("global.rules.max_date", {
      date: moment(max).format("DD.MM.YYYY"),
    });
  }

  return true;
});

for (const rule in rules) {
  defineRule(rule, rules[rule]);
}

export default () => {
  setLocale(store.getters["localization/getCurrent"]);
};
