const meta = {
  module: "profile",
};

export default [
  {
    path: "/profile",
    redirect: "/profile/info",
  },
  {
    path: "/profile/:action",
    name: "profile",
    component: () => import(/* webpackChunkName: "profile" */ `@/modules/${meta.module}/index.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "profile",
    },
  },
];
