export default {
  created(el: HTMLElement) {
    el.style.cursor = "pointer";
    el.addEventListener("click", () => {
      const image = el.cloneNode() as HTMLElement;
      const overlay: HTMLElement = document.createElement("div");
      const closeButton: HTMLElement = document.createElement("span");
      const imageContainer: HTMLElement = document.createElement("div");

      overlay.style.top = "0";
      overlay.style.opacity = "0";
      overlay.style.transition = "all 0.4s";
      overlay.style.left = "0";
      overlay.style.width = "100%";
      overlay.style.height = "100%";
      overlay.style.position = "100%";
      overlay.style.position = "fixed";
      overlay.style.zIndex = "99999999";
      overlay.style.display = "flex";
      overlay.style.alignItems = "center";
      overlay.style.justifyContent = "center";
      overlay.style.background = "rgba(0, 0, 0, 0.9)";

      imageContainer.style.position = "relative";
      imageContainer.style.height = "90vh";
      imageContainer.style.width = "90vw";
      imageContainer.style.display = "flex";
      imageContainer.style.alignItems = "center";
      imageContainer.style.justifyContent = "center";

      image.style.objectFit = "contain";
      image.style.width = "auto";
      image.style.height = "auto";
      image.style.maxWidth = "100%";
      image.style.maxHeight = "100%";
      image.style.borderRadius = "4px";

      closeButton.innerText = "✕";
      closeButton.style.position = "absolute";
      closeButton.style.right = "-4vw";
      closeButton.style.top = "-3vh";
      closeButton.style.color = "white";
      closeButton.style.padding = "4px 8px";
      closeButton.style.cursor = "pointer";
      closeButton.style.background = "transparent";
      closeButton.style.borderRadius = "50%";

      imageContainer.append(image, closeButton);
      overlay.append(imageContainer);
      document.body.appendChild(overlay);

      const remove = () => {
        overlay.style.opacity = "0";

        setTimeout(() => {
          overlay.remove();
          window.removeEventListener("keydown", removeFromESC);
        }, 400);
      };

      const removeFromESC = (e: any) => {
        const keys: any = { Escape: remove };

        keys[e.code]();
      };

      overlay.addEventListener("click", remove);

      window.addEventListener("keydown", removeFromESC);

      setTimeout(() => {
        overlay.style.opacity = "1";
      }, 100);
    });
  },
};
