interface HTMLExpandElement extends HTMLElement {
  _parent?: (Node & ParentNode & HTMLElement) | null;
  _initialStyle?: {
    transition: string;
    overflow: string;
    height?: string | null;
    width?: string | null;
  };
}

export default () => {
  const resetStyles = (el: HTMLExpandElement) => {
    el.style.overflow = el._initialStyle!.overflow;

    if (el._initialStyle!.height != null) {
      el.style.height = el._initialStyle!.height;
    }

    delete el._initialStyle;
  };

  return {
    afterEnter: resetStyles,
    enterCancelled: resetStyles,
    afterLeave: resetStyles,
    leaveCancelled: resetStyles,
    beforeEnter(el: HTMLExpandElement) {
      el._parent = el.parentNode as (Node & ParentNode & HTMLElement) | null;
      el._initialStyle = {
        transition: el.style.transition,
        overflow: el.style.overflow,
        height: el.style.height,
      };
    },
    enter(el: HTMLExpandElement) {
      el.style.setProperty("transition", "none", "important");
      el.style.overflow = "hidden";

      const offset = `${el.offsetHeight}px`;

      el.style.height = "0";
      el.style.transition = el._initialStyle!.transition;

      requestAnimationFrame(() => {
        el.style.height = offset;
      });
    },
    leave(el: HTMLExpandElement) {
      el._initialStyle = {
        transition: "",
        overflow: el.style.overflow,
        height: el.style.height,
      };

      el.style.overflow = "hidden";
      el.style.height = `${el.offsetHeight}px`;

      requestAnimationFrame(() => {
        el.style.height = "0";
      });
    },
  };
};
