import ApiModel from "../APIModel";

export default class Core extends ApiModel {
  constructor() {
    super("v1/core");
  }

  exchangeRate(): Promise<any> {
    return this.http.ignoreErrorHandler().get(`${this.resourceUrl}/exchange_rate`, {});
  }

  navigation(): Promise<any> {
    return this.http.ignoreErrorHandler().get(`${this.resourceUrl}/navigation`, {});
  }

  agencyContract(payload: object): Promise<any> {
    return this.http.ignoreErrorHandler().post(`/contract/agency`, payload);
  }

  async contractVersion(payload = {}) {
    return await this.http.ignoreErrorHandler().get(`/contract/agency`, payload);
  }

  async banksList(payload = {}) {
    return await this.http.ignoreErrorHandler().get(`/contract/banks`, payload);
  }

  async bankBranchList(bankId: number) {
    return await this.http.ignoreErrorHandler().get(`/contract/banks/${bankId}/list `, {});
  }
}
