const meta = {
  module: "agencies",
};

export default [
  {
    path: "/agency",
    redirect: "/agency/commission",
  },
  {
    path: "/agency/:action",
    name: "agencies",
    component: () => import(/* webpackChunkName: "agencies" */ `@/modules/${meta.module}/index.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "agencies",
    },
  },
];
