<template>
  <li v-extend-checkbox v-if="model">
    <div :class="{ bold: model.children && model.children.length > 1, 'tree-row-item': true }">
      <div class="tree-row-txt">
        <b-form-checkbox v-model="model.checked" :indeterminate="indeterminate" @change="changeCheck">
          <slot :name="`label`" v-bind="model">{{ model.label }}</slot>
        </b-form-checkbox>
      </div>
      <div
        v-if="model.children && model.children.length > 1"
        class="tree-row-item-icon-wrapper"
        @click.stop="toggleTree"
      >
        <ui-icon size="16" :name="isOpen ? 'angle-up' : 'angle-down'" />
      </div>
    </div>
    <ul v-if="model.children && model.children.length > 1" v-show="isOpen" class="tree-list" @click.stop>
      <tree-block
        v-for="(_, index) in model.children"
        :key="index"
        @update:modelValue="changeChildren(index, $event)"
        :model-value="model.children[index]"
        class="tree-row"
      />
    </ul>
  </li>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref, watch } from "vue";
import TreeBlock from "./TreeBlock";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});

const isOpen = ref(true);
const model = ref(null);

watch(
  () => props.modelValue,
  () => {
    model.value = JSON.parse(JSON.stringify(props.modelValue));
  },
  {
    immediate: true,
    deep: true,
  }
);

const indeterminate = computed(() => {
  const checked = model.value?.children?.filter(({ checked }) => checked) || [];

  return checked.length > 1 && checked.length < model.value.children.length;
});

const toggleTree = () => {
  isOpen.value = !isOpen.value;
};

const changeChildren = (index, item) => {
  model.value.children.splice(index, 1, item);
  const checked = model.value.children.filter(({ checked }) => checked) || [];

  if (!checked.length) {
    model.value.checked = false;
  }
  emit("update:modelValue", JSON.parse(JSON.stringify(model.value)));
};

const changeCheck = (checked) => {
  if (model.value.hasOwnProperty("children")) {
    model.value.children = model.value.children.map((item) => ({
      ...item,
      checked,
    }));
  }

  emit("update:modelValue", JSON.parse(JSON.stringify(model.value)));
};
</script>
