import { ActionContext, ActionPayload } from "vuex";

interface StateInterface {
  menu: boolean;
  mobileMenu: boolean;
  items: any[];
}

export default {
  namespaced: true,

  state: {
    menu: false,
    mobileMenu: false,
    items: [],
  } as StateInterface,

  getters: {
    show: (state: StateInterface) => state.menu,
    menu: (state: StateInterface) => state.items,
    showMobile: (state: StateInterface) => state.mobileMenu,
  },

  mutations: {
    toggleMenu: (state: StateInterface) => {
      state.menu = !state.menu;
    },
    toggleMobileMenu: (state: StateInterface) => {
      state.mobileMenu = !state.mobileMenu;
    },
    setMenu: (state: StateInterface, value: any) => {
      state.menu = !!value;
    },
    setMobileMenu: (state: StateInterface, value: any) => {
      state.mobileMenu = !!value;
    },
    setMenuItems: (state: StateInterface, value: any) => {
      state.items = value;
    },
  },

  actions: {
    toggleMenu: ({ commit }: ActionContext<StateInterface, "{}">) => {
      commit("toggleMenu");
    },
    toggleMobileMenu: ({ commit }: ActionContext<StateInterface, "{}">) => {
      commit("toggleMobileMenu");
    },
    setMenu: ({ commit }: ActionContext<StateInterface, "{}">, value: ActionPayload) => {
      commit("setMenu", value);
    },
    setMobileMenu: ({ commit }: ActionContext<StateInterface, "{}">, value: ActionPayload) => {
      commit("setMobileMenu", value);
    },
    setMenuItems: ({ commit }: ActionContext<StateInterface, "{}">, value: ActionPayload) => {
      commit("setMenuItems", value);
    },
  },
};
