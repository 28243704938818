import { createStore } from "vuex";
import navigation from "./modules/navigation";
import auth from "./modules/auth";
import localization from "./modules/localization";
import preloader from "./modules/preloader";
import alert from "./modules/alert";
import app from "./modules/app";

export default createStore({
  modules: {
    app,
    auth,
    alert,
    preloader,
    localization,
    navigation,
  },
});
