import { useStore } from "vuex";
import moment from "@/plugins/moment";
import { useI18n } from "vue-i18n";

const useRowCopy = () => {
  const store = useStore();
  const { t } = useI18n();

  const getRowString = (item: any, price: any) => {
    const table = [
      `${moment(item.dates.check_in).format("DD.MM.YYYY")} - ${moment(item.dates.check_out).format("DD.MM.YYYY")} (${
        item.nights.total
      } ${t("global.saved_offer.nights", item.nights.total)})`,
      `${item.accommodation.hotel.name} ${item.accommodation.hotel.category} / ${item.accommodation.meal.name} ${item.accommodation.hotel.city}`,
      `${item.accommodation.room.name}`,
      `${item.accommodation.placement.name}`,
      "",
      `${price}${t(`global.currency.${item.price.currency}`)}`,
    ];

    if (!Array.isArray(item.tickets)) {
      table.unshift(
        `${t("global.labels.departure")} ${moment(item.tickets.from.departure.date).format("DD.MM.YYYY")} ${
          item.tickets.from.departure.time
        }`,
        `${moment(item.tickets.from.departure.date).format("ddd")}. ${item.tickets.from.airline.iata_code}${
          item.tickets.from.name
        }`,
        `${item.tickets.from.airports.from.name} - ${item.tickets.from.airports.to.name}`,
        "",
        `${t("global.labels.arrival")} ${moment(item.tickets.to.arrival.date).format("DD.MM.YYYY")} ${
          item.tickets.to.arrival.time
        }`,
        `${moment(item.tickets.to.departure.date).format("ddd")}. ${item.tickets.to.airline.iata_code}${
          item.tickets.to.name
        }`,
        `${item.tickets.to.airports.from.name} - ${item.tickets.to.airports.to.name}`,
        ""
      );
    }

    return table.join("\n");
  };

  const copyToClipboard = async (item: any, price: any) => {
    const string = getRowString(item, price);

    await navigator.clipboard.writeText(string);
    await store.dispatch("alert/show", {
      text: t("global.clipboard_copy_success"),
      type: "success",
    });
  };

  const copyAllToClipboard = async (model: any) => {
    const string = model
      .map(({ search_result: item, price }: any) => getRowString(item, price))
      .join("\n\n------------------------------\n\n");

    await navigator.clipboard.writeText(string);

    await store.dispatch("alert/show", {
      text: t("global.clipboard_copy_success"),
      type: "success",
    });
  };

  return {
    copyToClipboard,
    copyAllToClipboard,
  };
};

export default useRowCopy;
