const meta = {
  module: "employees",
};

export default [
  {
    path: "/employees/:office?",
    name: "employees",
    component: () => import(/* webpackChunkName: "employees" */ `@/modules/${meta.module}/index.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "employees",
    },
  },
];
