<script setup>
import { useStore } from "vuex";
import LangSwitcher from "@/components/navigation/navbar/LangSwitcher";
import Profile from "@/components/navigation/navbar/Profile";
import Currency from "@/components/navigation/navbar/Currency";
import Notifications from "@/components/dialogs/Notifications";
import Contacts from "@/components/navigation/navbar/Contacts";
import { useRoute } from "vue-router";
import Offers from "@/components/navigation/navbar/Offers";
import { useI18n } from "vue-i18n";
import { computed } from "vue";

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const appTitle = store.getters["app/title"];
const appAbbreviation = store.getters["app/abbreviation"];

const moduleName = computed(() => {
  const title = t(`${route.meta.titleLocalizationPath}.title`);

  if (title.includes(".") && !title.includes(" ")) {
    console.error(`Title localization for route ${route.name} not found`);
    return "";
  }

  return title;
});

const logout = () => {
  store.dispatch("auth/logout");
};
</script>

<template>
  <nav class="navbar navbar-light bg-primary fixed-top">
    <div class="container-fluid">
      <div
        :class="{ open: store.getters['navigation/show'] }"
        class="navbar-brand cursor-pointer"
        @click="$router.push('/')"
      >
        <span class="short">{{ appAbbreviation }}</span>
        <span class="ms-3" v-if="appAbbreviation !== appTitle">{{ appTitle }}</span>
      </div>
      <div class="d-flex flex-grow-1 align-items-center me-lg-3 me-2">
        <div class="me-auto">
          <b-button
            class="text-light d-none d-md-block"
            variant="transparent"
            @click="store.dispatch('navigation/toggleMenu')"
          >
            <ui-icon size="20" name="bars" />
          </b-button>
          <div class="d-flex d-md-none align-content-center">
            <div
              class="cursor-pointer d-flex align-self-center ms-3"
              @click="store.dispatch('navigation/toggleMobileMenu')"
            >
              <ui-icon color="var(--bs-white)" name="bars" size="24px" />
            </div>
            <div class="fs-18px fw-bold text-white d-flex align-self-center ms-4" style="line-height: 1">
              {{ moduleName }}
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <contacts class="me-md-4 me-18px"></contacts>
          <offers class="me-md-4 me-18px"></offers>
          <notifications class="me-md-4 me-18px"></notifications>
          <currency></currency>
          <profile class="d-none ms-md-4 ms-18px d-md-block"></profile>
          <lang-switcher class="d-none d-md-flex"></lang-switcher>
        </div>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.me-18px {
  margin-right: 18px;
}

.ms-18px {
  margin-right: 18px;
}
</style>
