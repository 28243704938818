import { ActionContext } from "vuex";

interface ConfigInterface {
  title: string;
  abbreviation: string;
  logo: string;
  favicon: string;
  searchIsland: string;
}

interface StateInterface {
  config: ConfigInterface;
}

export default {
  namespaced: true,

  state: {
    config: {
      title: "",
      logo: "",
      favicon: "",
      abbreviation: "",
    },
  } as StateInterface,

  mutations: {
    set(state: StateInterface, payload: ConfigInterface) {
      state.config = { ...state.config, ...payload };
    },
  },

  actions: {
    set(context: ActionContext<StateInterface, "{}">, payload: ConfigInterface) {
      context.commit("set", payload);
    },
  },

  getters: {
    config: (state: StateInterface): ConfigInterface => state.config,
    title: (state: StateInterface): string => state.config.title,
    abbreviation: (state: StateInterface): string => state.config.abbreviation,
    logo: (state: StateInterface): string => state.config.logo,
    searchIsland: (state: StateInterface): string => state.config.searchIsland,
    favicon: (state: StateInterface): string => state.config.favicon,
  },
};
