import moment from "@/plugins/moment";

export const toBase64 = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });
};

export function diff(a: number, b: number) {
  return Math.abs(a - b);
}

export function moneyFormat(money: string, minimumFractionDigits = 2) {
  return `${Intl.NumberFormat("en", { minimumFractionDigits })?.format(parseFloat(money))}`.replace(",", " ");
}

export function randString(length: number) {
  return Array(length)
    .fill(null)
    .map(() => "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".charAt(Math.random() * 62))
    .join("");
}

export function highlight(val: any, search = "") {
  if (typeof val === "undefined" || !search) {
    return val;
  }

  let replacedString = val;
  if (search.length) replacedString = val.replace(search.toUpperCase(), (match: string) => `<mark>${match}</mark>`);

  return replacedString;
}

export const localeDate = (date: string) => {
  if (!date) {
    return "";
  }

  return moment(date).format("DD.MM.YYYY");
};

export const getUniqueKey = () => `${Math.floor(Math.random() * 10e12)}`;
