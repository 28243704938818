import APIModel from "../APIModel";

export default class Agencies extends APIModel {
  constructor() {
    super("agencies");
  }

  get(params = {}): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/profile`, params);
  }

  post(payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(422).useFormData().post(`${this.resourceUrl}/profile`, payload);
  }

  commission(payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(422).post(`${this.resourceUrl}/profile`, payload);
  }

  remove(id: number): Promise<any> {
    return this.http.ignoreErrorHandler(500).delete(`${this.resourceUrl}/profile/logo/${id}`, {});
  }
}
