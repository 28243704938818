<script setup>
import { computed, defineEmits, defineProps, ref, useAttrs, watch } from "vue";
import moment from "moment";
import { useStore } from "vuex";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  startDate: {
    type: String,
    default: moment().format("YYYY-MM-DD"),
  },
  markers: {
    type: Object,
    default: () => ({}),
  },
  allowedDates: {
    type: Array,
    default: () => [],
  },
  name: {
    type: String,
    default: () => "Field" + Math.floor(Math.random() * 100),
  },
  rules: {
    type: String,
    default: () => "",
  },
  iconText: {
    type: String,
    default: () => "",
  },
  icon: {
    type: String,
    default: () => "calendar-alt",
  },
  preventInputClick: {
    type: Boolean,
    default: () => false,
    required: false,
  },
  bordered: {
    type: Boolean,
    default: false,
    required: false,
  },
  minDate: {
    type: String,
    default: () => moment().subtract(100, "year").format("YYYY-MM-DD"),
  },
  maxDate: {
    type: String,
    default: () => moment().add(5, "year").format("YYYY-MM-DD"),
  },
});

const model = ref({
  start: null,
  end: null,
});
const store = useStore();
const attrs = useAttrs();

watch(
  () => props.modelValue,
  ([start, end]) => {
    model.value = {
      start: start || null,
      end: end || null,
    };
  },
  {
    immediate: true,
  }
);

const initialPage = computed(() => {
  if (props.startDate) {
    return {
      year: moment(props.startDate).year(),
      month: moment(props.startDate).month() + 1,
    };
  }

  return undefined;
});

const update = () => {
  if (model.value && model.value.end && model.value.start) {
    emit("update:modelValue", [
      moment(model.value.start).format("YYYY-MM-DD"),
      moment(model.value.end).format("YYYY-MM-DD"),
    ]);
  } else {
    emit("update:modelValue", []);
  }
};

const clearModel = () => {
  emit("update:modelValue", []);
};

const toggleCalendar = (callback) => {
  if (!attrs.hasOwnProperty("disabled") || attrs.disabled === false) {
    callback();
  }
};
</script>

<template>
  <v-date-picker
    borderless
    v-model.range="model"
    :locale="store.getters['localization/getCurrent']"
    @update:modelValue="update"
    :min-date="props.minDate"
    :max-date="props.maxDate"
    :initial-page="initialPage"
    :popover="{
      visibility: 'click',
      placement: 'bottom-start',
      positionFixed: true,
    }"
  >
    <template #default="{ togglePopover }">
      <div class="position-relative input-container h-100" :class="{ bordered }">
        <input
          :value="
            model && model.start && model.end
              ? `${moment(model.start).format('DD.MM')} - ${moment(model.end).format('DD.MM')}`
              : ''
          "
          readonly
          type="text"
          class="h-100"
          :placeholder="$t('global.select')"
          @click="toggleCalendar(togglePopover)"
        />
        <div class="icon">
          <ui-icon
            @click="clearModel()"
            v-if="model && model.start && model.end"
            name="times"
            size="18"
            color="var(--gray-3)"
          ></ui-icon>
        </div>
      </div>
    </template>
  </v-date-picker>
</template>

<style lang="scss" scoped>
.bordered {
  input {
    border: 1px solid #bdbdbd;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
  }

  .icon {
    right: 12px;
  }
}

.input-container {
  &:hover {
    .icon {
      display: block;
    }
  }

  input {
    font-size: 14px;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 0;
    width: 32px;
    transform: translateY(-50%);
    cursor: pointer;
    display: none;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 30%);
  }
}
</style>
