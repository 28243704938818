import { ActionContext } from "vuex";

interface StateInterface {
  show: number[];
  showGlobal: boolean;
}

export default {
  namespaced: true,

  state: {
    show: [],
    showGlobal: true,
  } as StateInterface,

  mutations: {
    show(state: StateInterface) {
      state.show.push(1);
    },
    hide(state: StateInterface) {
      state.show.pop();
    },
    showGlobal(state: StateInterface) {
      state.showGlobal = true;
    },
    hideGlobal(state: StateInterface) {
      state.showGlobal = false;
    },
  },

  actions: {
    show(context: ActionContext<StateInterface, "{}">) {
      context.commit("show");
    },
    hide(context: ActionContext<StateInterface, "{}">) {
      context.commit("hide");
    },
    showGlobal({ commit }: ActionContext<StateInterface, "{}">) {
      commit("showGlobal");
    },
    hideGlobal({ commit }: ActionContext<StateInterface, "{}">) {
      commit("hideGlobal");
    },
  },

  getters: {
    show: (state: StateInterface): boolean => !!state.show.length,
    showGlobal: (state: StateInterface): boolean => state.showGlobal,
  },
};
