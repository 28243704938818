<template>
  <b-input-group-prepend>
    <v-select
      v-model="selectedCountry"
      :clearable="false"
      :options="Object.values(countries)"
      :searchable="false"
      class="choose-flag"
      style="width: 116px"
    >
      <template #selected-option="{ flag, code }">
        <span :class="`flag-icon flag-icon-${flag} me-2`"></span> {{ code }}
      </template>
      <template #option="{ flag, label }"><span :class="`flag-icon flag-icon-${flag}`"></span> {{ label }}</template>
    </v-select>
  </b-input-group-prepend>
  <ui-input
    v-model="phone"
    :mask="phoneMask"
    :name="props.name"
    :placeholder="phonePlaceholder"
    :rules="`min:${phoneMask.length}|${rules}`"
    autocomplete="off"
    class="text-start"
    type="number"
  />
</template>

<script setup>
import { computed, defineEmits, defineProps, nextTick, ref, watch } from "vue";
import VSelect from "vue-select";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: [Number, String],
    default() {
      return "";
    },
  },
  name: {
    type: String,
    default: () => "",
  },
  rules: {
    type: String,
    default: () => "",
  },
});

const countries = ref({
  md: {
    flag: "md",
    code: "+373",
    label: "Moldova (+373)",
    mask: "########",
    placeholder: "99999999",
  },
  ro: {
    flag: "ro",
    code: "+40",
    label: "Romania (+40)",
    mask: "#########",
    placeholder: "999999999",
  },
  ua: {
    flag: "ua",
    code: "+380",
    label: "Украина (+380)",
    mask: "#########",
    placeholder: "999999999",
  },
});
const appSystem = process.env.VUE_APP_SYSTEM.toLowerCase();
const defaultCountry = countries.value.hasOwnProperty(appSystem) ? countries.value[appSystem] : "ro";
const selectedCountry = ref({ ...defaultCountry });
const phoneMask = ref(defaultCountry.mask);
const phonePlaceholder = ref(defaultCountry.placeholder);
const phone = ref();
const phoneCode = ref(defaultCountry.code);
const rules = ref("");

const phoneFormatted = computed(() => {
  if (!phone?.value) {
    return "";
  }

  return `${phoneCode?.value || ""}${phone?.value}`;
});

watch(selectedCountry, (val) => {
  phoneMask.value = val.mask;
  phoneCode.value = val.code;
  phonePlaceholder.value = val.placeholder;
});

watch(
  () => phoneCode.value,
  () => {
    emit("update:modelValue", phoneFormatted.value);
  }
);

watch(
  () => props.modelValue,
  () => {
    const codes = Object.values(countries.value)
      .map((el) => el.code)
      .flat()
      .join("|")
      .replaceAll("+", "\\+");

    nextTick(() => {
      const initialRules = props.rules;

      rules.value = initialRules
        .split("|")
        .map((item) => {
          if (item.includes("not_one_of")) {
            const [key, list] = item.split(":");

            if (!list) {
              return item;
            }

            return `${key}:${list
              .split(",")
              .filter((el) => !!el)
              .map((el) => {
                const [code] = el?.match(`^(${codes})`);

                return el.replace(code, "");
              })
              .toString()}`;
          }

          return item;
        })
        .join("|");

      if (props.modelValue) {
        const [code] = props.modelValue.match(`^(${codes})`);

        phone.value = props.modelValue.replace(code, "");
        selectedCountry.value = Object.values(countries.value).find((el) => el.code === code);
        phoneCode.value = code;
      } else {
        phoneCode.value = defaultCountry.code;
        selectedCountry.value = { ...defaultCountry };
        phone.value = "";
      }
    });
  },
  { immediate: true }
);
watch(
  () => phone.value,
  () => {
    emit("update:modelValue", phoneFormatted.value);
  }
);
</script>

<style lang="scss">
.choose-flag {
  .vs__dropdown-toggle {
    height: 100%;
  }

  .vs__dropdown-option {
    &--selected {
      width: auto !important;
      padding-right: 30px;

      &::after {
        right: 8px;
      }
    }
  }

  .vs__dropdown-menu {
    width: auto !important;
  }
}
</style>

<style src="flag-icon-css/css/flag-icons.css"></style>
