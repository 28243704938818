export default {
  created(el: any) {
    el.addEventListener("click", () => {
      el.querySelector("input").click();
    });

    el.querySelector("label").addEventListener("click", (e: any) => {
      e.stopPropagation();
    });
  },
};
