import ApiModel from "../APIModel";

export default class Offers extends ApiModel {
  constructor() {
    super("search");
  }

  async getTours(payload = {}) {
    return await this.http.ignoreErrorHandler(404, 422, 500).getAxiosInstance().post(`${this.resourceUrl}`, payload);
  }

  departureCities(): Promise<any> {
    return this.http.ignoreErrorHandler(422, 500).get(`v2/${this.resourceUrl}/departure_cities`, {});
  }

  countries(params: object): Promise<any> {
    return this.http.ignoreErrorHandler(422, 500).get(`v2/${this.resourceUrl}/countries`, params);
  }

  calendarHints(params: object): Promise<any> {
    return this.http.ignoreErrorHandler(422, 500).get(`v2/${this.resourceUrl}/calendar_hints`, params);
  }

  availableNights(params: object): Promise<any> {
    return this.http.ignoreErrorHandler(422, 500).get(`v2/${this.resourceUrl}/available_nights`, params);
  }

  packageTemplates(country_id: number | string, params: object): Promise<any> {
    return this.http
      .ignoreErrorHandler(422, 500)
      .get(`v2/${this.resourceUrl}/countries/${country_id}/package_templates`, params);
  }

  locations(package_id: number | string): Promise<any> {
    return this.http
      .ignoreErrorHandler(422, 500)
      .get(`v2/${this.resourceUrl}/package_templates/${package_id}/locations`, {});
  }

  meals(package_id: number | string): Promise<any> {
    return this.http
      .ignoreErrorHandler(422, 500)
      .get(`v2/${this.resourceUrl}/package_templates/${package_id}/meals`, {});
  }

  categories(package_id: number | string): Promise<any> {
    return this.http
      .ignoreErrorHandler(422, 500)
      .get(`v2/${this.resourceUrl}/package_templates/${package_id}/hotel_categories`, {});
  }

  hotels(package_id: number, payload: object): Promise<any> {
    return this.http
      .ignoreErrorHandler(422, 500)
      .get(`v2/${this.resourceUrl}/package_templates/${package_id}/hotels`, payload);
  }

  submit(payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(404, 422, 500).post(`v2/${this.resourceUrl}`, payload);
  }

  getOffers(params = {}): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/offers`, params);
  }

  postOffers(payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(422).post(`${this.resourceUrl}/offers`, payload);
  }

  patchOffers(id: number, payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(422).patch(`${this.resourceUrl}/offers/${id}`, payload);
  }

  deleteOffers(payload: Array<number>): Promise<any> {
    return this.http.delete(`${this.resourceUrl}/offers`, payload);
  }

  printOffers(payload: Array<number>): Promise<any> {
    return this.http
      .setResponseType("blob")
      .ignoreErrorHandler(422, 500)
      .post(`${this.resourceUrl}/offers/print`, payload);
  }

  contacts(payload = {}): Promise<any> {
    return this.http.get(`${this.resourceUrl}/offers/contacts`, payload);
  }

  totalOffers(payload = {}): Promise<any> {
    return this.http.get(`${this.resourceUrl}/offers/total`, payload);
  }
}
