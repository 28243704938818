import ApiModel from "../APIModel";

export default class Ats extends ApiModel {
  constructor() {
    super("ats");
  }

  salesReport(params = {}): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/reports/sales`, params);
  }

  salesReportFilters(): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/reports/sales-filters`, {});
  }

  ordersProperties(params = {}): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/orders-properties`, params);
  }

  saveOrdersProperties(payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(500).patch(`${this.resourceUrl}/orders-properties `, payload);
  }

  orders(params = {}): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/orders`, params);
  }

  getOrder(id: string): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/orders/${id}`, {});
  }

  getEditedOrder(id: string): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/orders/${id}/edit`, {});
  }

  saveOrder(payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(404, 422).post(`${this.resourceUrl}/orders`, payload);
  }

  patchOrder(id: number | string, payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(422).patch(`${this.resourceUrl}/orders/${id}`, payload);
  }

  ordersSelects(): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/orders/form-options`, {});
  }

  ordersFilters(): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/orders/filters`, {});
  }

  userLinks(params = {}): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/user-links`, params);
  }

  getUserLink(id: string): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/user-links/${id}`, {});
  }

  getEditedUserLink(id: string): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/user-links/${id}/edit`, {});
  }

  saveUserLink(payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(404, 422).post(`${this.resourceUrl}/user-links`, payload);
  }

  patchUserLink(id: number | string, payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(422).patch(`${this.resourceUrl}/user-links/${id}`, payload);
  }

  userLinksSelects(): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/user-links/additional`, {});
  }

  userLinksFilters(): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/user-links/filters`, {});
  }

  ordersPayments(params = {}): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/orders-payments`, params);
  }

  getOrderPayments(id: string): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/orders-payments/${id}`, {});
  }

  getOrderPaymentsInfo(params: object): Promise<any> {
    return this.http.ignoreErrorHandler(422, 500).get(`${this.resourceUrl}/orders-payments/create`, params);
  }

  getEditedOrderPayments(id: string): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/orders-payments/${id}/edit`, {});
  }

  saveOrdersPayments(payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(404, 422).post(`${this.resourceUrl}/orders-payments`, payload);
  }

  patchOrdersPayments(id: number | string, payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(422).patch(`${this.resourceUrl}/orders-payments/${id}`, payload);
  }

  ordersPaymentsSelects(): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/orders-payments/additional`, {});
  }

  ordersPaymentsFilters(): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/orders-payments/filters`, {});
  }
}
