<script setup>
import { defineProps } from "vue";
import AirCompany from "@/components/tour/AirCompany.vue";
import { breakpointsBootstrapV5, useBreakpoints } from "@vueuse/core";
import moment from "@/plugins/moment";
import UiIcon from "@/components/global/UiIcon";

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  flip: {
    type: Boolean,
    default: () => false,
  },
});

const breakpoints = useBreakpoints(breakpointsBootstrapV5);
</script>

<template>
  <div>
    <b-card class="border-0 radius disable-print h-100" no-body>
      <div class="px-3 py-md-3 py-2 my-1 my-md-0">
        <b-row gutter-x="3">
          <b-col cols="auto">
            <ui-icon
              size="20"
              :style="{ transform: flip ? 'rotate(-90deg)' : 'rotate(90deg)' }"
              name="plane"
              color="var(--primary)"
            />
          </b-col>
          <b-col>
            <span class="fs-16px text-gray-1 text-bold text-nowrap">
              {{ $t(flip ? "global.flight_back" : "global.flight_there") }}:
              {{ moment(item?.departure?.date).format("DD.MM.YYYY") }}
            </span>
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-lg-end justify-content-start mt-2 mt-lg-0"
            cols="12"
            lg="auto"
          >
            <span class="fw-bold fs-18px"> {{ item?.airline?.airline }}</span>
            <air-company :name="item?.airline?.iata_code" class="me-2 ms-2" height="18" width="18" />
            <span class="text-bold fs-18px">
              <span :style="{ color: item?.airline?.color }">
                {{ item?.airline?.iata_code }}
              </span>
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-3" gutter-x="3">
          <b-col cols="12" xl="6">
            <span class="fs-16px text-gray-1 text-bold text-nowrap">
              {{ item?.departure?.time }}
              <ui-icon class="text-gray-3" size="20" name="plane-fly" />
            </span>
            <div>
              <span class="fs-14px text-gray-1">
                {{ item?.airports?.from.name }}
              </span>
              <span class="fs-14px text-gray-3 ms-1">
                {{ item?.airports?.from.prefix }}
              </span>
            </div>
          </b-col>
          <b-col class="text-xl-end mt-3 mt-xl-0" cols="12" xl="6">
            <span class="fs-16px text-gray-1 text-bold">
              {{ item?.arrival?.time }}
              <ui-icon class="text-gray-3" size="20" name="plane-arrival" />
            </span>
            <div>
              <span class="fs-14px text-gray-1">
                {{ item?.airports?.to.name }}
              </span>
              <span class="fs-14px text-gray-3 ms-1">
                {{ item?.airports?.to.prefix }}
              </span>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card class="border-0 radius printable" no-body style="height: 98px">
      <div class="p-2">
        <b-row gutter-x="3">
          <b-col cols="auto">
            <ui-icon
              size="20"
              :style="{ transform: flip ? 'rotate(-90deg)' : 'rotate(90deg)' }"
              name="plane"
              color="var(--primary)"
            />
          </b-col>
          <b-col>
            <span class="fs-16px text-gray-1 text-bold text-nowrap">
              {{ $t(flip ? "global.flight_back" : "global.flight_there") }}:
              {{ moment(item?.departure?.date).format("DD.MM.YYYY") }}
            </span>
          </b-col>
          <b-col class="text-end">
            <span class="fw-bold"> {{ item?.airline?.airline }}</span>
            <air-company :name="item?.airline?.iata_code" class="me-2 ms-2" height="18" width="18" />
            <span class="text-bold fs-16px">
              <span :style="{ color: item?.airline?.color }">
                {{ item?.airline?.iata_code }}
              </span>
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-2 align-items-start" gutter-x="3">
          <b-col cols="6" xl="6">
            <span class="fs-16px text-gray-1 text-bold text-nowrap">
              {{ item?.departure?.time }}
              <ui-icon class="text-gray-3" size="20" name="plane-fly" />
            </span>
            <div>
              <span class="fs-14px text-gray-1">
                {{ item?.airports?.from.name }}
              </span>
              <span class="fs-14px text-gray-3 ms-1">
                {{ item?.airports?.from.prefix }}
              </span>
            </div>
          </b-col>
          <b-col class="text-end" cols="6" xl="6">
            <span class="fs-16px text-gray-1 text-bold">
              {{ item?.arrival?.time }}
              <ui-icon class="text-gray-3" size="20" name="plane-arrival" />
            </span>
            <div>
              <span class="fs-14px text-gray-1">
                {{ item?.airports?.to.name }}
              </span>
              <span class="fs-14px text-gray-3 ms-1">
                {{ item?.airports?.to.prefix }}
              </span>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
