import ApiModel from "../APIModel";
import { AxiosResponse } from "axios";

export default class Profile extends ApiModel {
  constructor() {
    super("v1/profile");
  }

  changePassword(payload: object): Promise<AxiosResponse> {
    return this.http.ignoreErrorHandler(422).post(`${this.resourceUrl}/change_password`, payload);
  }

  settings(payload: object): Promise<AxiosResponse> {
    return this.http.ignoreErrorHandler(422).post(`${this.resourceUrl}/settings`, payload);
  }

  commission(payload: object): Promise<AxiosResponse> {
    return this.http.ignoreErrorHandler(422).post(`${this.resourceUrl}/commission`, payload);
  }

  closeOtherSessions(): Promise<AxiosResponse> {
    return this.http.ignoreErrorHandler(422).post(`${this.resourceUrl}/terminate`, {});
  }

  closeSession(token: string): Promise<AxiosResponse> {
    return this.http.ignoreErrorHandler(422).post(`${this.resourceUrl}/terminate/${token}`, {});
  }

  uploadImage(payload: object): Promise<AxiosResponse> {
    return this.http.ignoreErrorHandler(422).useFormData().post(`v1/avatars`, payload);
  }

  deleteImage(): Promise<AxiosResponse> {
    return this.http.ignoreErrorHandler(422).patch(`v1/avatars`, {});
  }

  edit(payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(422).patch(`${this.resourceUrl}`, payload);
  }
}
