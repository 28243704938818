import Auth from "@/services/api/modules/Auth";
import Orders from "@/services/api/modules/Orders";
import Search from "@/services/api/modules/Search";
import Book from "@/services/api/modules/Book";
import Profile from "@/services/api/modules/Profile";
import Core from "@/services/api/modules/Core";
import Employees from "@/services/api/modules/Employees";
import FlightSearch from "@/services/api/modules/FlightSearch";
import Rules from "@/services/api/modules/Rules";
import News from "@/services/api/modules/News";
import Notifications from "@/services/api/modules/Notifications";
import Subpartitions from "@/services/api/modules/Subpartitions";
import Agencies from "@/services/api/modules/Agencies";
import Giata from "@/services/api/modules/Giata";
import Ats from "@/services/api/modules/Ats";
import PaymentGateways from "@/services/api/modules/PaymentGateways";

export default {
  auth: () => new Auth(),
  agencies: () => new Agencies(),
  orders: () => new Orders(),
  search: () => new Search(),
  book: () => new Book(),
  profile: () => new Profile(),
  core: () => new Core(),
  employees: () => new Employees(),
  flightSearch: () => new FlightSearch(),
  rules: () => new Rules(),
  news: () => new News(),
  notifications: () => new Notifications(),
  subpartitions: () => new Subpartitions(),
  giata: () => new Giata(),
  ats: () => new Ats(),
  paymentGateways: () => new PaymentGateways(),
};
