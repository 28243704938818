import i18n from "@/plugins/i18n";

export default {
  created(el: HTMLElement, bindings: any) {
    el.style.cursor = "pointer";
    el.addEventListener("click", () => {
      const overlay: HTMLElement = document.createElement("div");
      const modal: HTMLElement = document.createElement("div");
      const closeButton: HTMLElement = document.createElement("button");
      const confirmButton: HTMLElement = document.createElement("button");
      const title: HTMLElement = document.createElement("div");
      const message: HTMLElement = document.createElement("div");
      const actions: HTMLElement = document.createElement("div");

      overlay.style.top = "0";
      overlay.style.opacity = "0";
      overlay.style.transition = "all 0.4s";
      overlay.style.left = "0";
      overlay.style.width = "100%";
      overlay.style.height = "100%";
      overlay.style.position = "100%";
      overlay.style.position = "fixed";
      overlay.style.zIndex = "99999999";
      overlay.style.display = "flex";
      overlay.style.alignItems = "center";
      overlay.style.justifyContent = "center";
      overlay.style.background = "rgba(0, 0, 0, 0.6)";

      modal.style.padding = "48px 80px";
      modal.style.width = "454px";
      modal.style.background = "#FFFFFF";
      modal.style.boxShadow = "0px 14px 44px rgba(0, 0, 0, 0.2)";
      modal.style.borderRadius = "16px";

      title.classList.add("fw-bold", "fs-24px", "text-gray-1", "text-center");
      title.innerText = bindings.value.title;

      message.classList.add("fw-medium", "fs-16px", "text-gray-1", "text-center", "mt-2");
      message.innerText = bindings.value.message;

      actions.classList.add("mt-4", "d-flex", "justify-content-center");

      closeButton.classList.add("btn", "btn-outline-primary", "me-3");
      closeButton.innerText = i18n.global.t("global.buttons.cancel");

      confirmButton.classList.add("btn", "btn-primary");
      confirmButton.innerText = i18n.global.t("global.buttons.confirm");

      actions.append(closeButton, confirmButton);
      modal.append(title, message, actions);
      overlay.append(modal);
      document.body.appendChild(overlay);

      const remove = () => {
        overlay.style.opacity = "0";

        setTimeout(() => {
          overlay.remove();
          window.removeEventListener("keydown", removeFromESC);
        }, 400);
      };

      const removeFromESC = (e: any) => {
        const keys: any = { Escape: remove };

        keys[e.code]();
      };

      confirmButton.addEventListener("click", () => {
        bindings.value.callback();
        remove();
      });
      closeButton.addEventListener("click", remove);
      overlay.addEventListener("click", remove);
      window.addEventListener("keydown", removeFromESC);

      setTimeout(() => {
        overlay.style.opacity = "1";
      }, 100);
    });
  },
};
