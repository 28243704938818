import { createApp } from "vue";

import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
import validate from "./plugins/vee-validate";

//sw script
import "./registerServiceWorker";

//global styles
import "./styles/main.scss";
import "./styles/transitions.scss";
import "v-calendar/style.css";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

//global directives
import expanded from "@/directives/expanded";
import clipboard from "@/directives/clipboard";
import confirm from "@/directives/confirm";
import extendCheckbox from "@/directives/extend-checkbox";
import handleEsc from "@/directives/handle-esc";
import popup from "@/directives/popup";
import tooltip from "@/directives/tooltip";
import { maska } from "maska";

//global components
import Popper from "vue3-popper";
import { DatePicker } from "v-calendar";
import { Form } from "vee-validate";
import UiAlert from "@/components/global/UiAlert.vue";
import UiCalendar from "@/components/global/UiCalendar.vue";
import UiCalendarFlat from "@/components/global/UiCalendarFlat.vue";
import UiInput from "@/components/global/UiInput.vue";
import UiMultiSelect from "@/components/global/UiMultiSelect.vue";
import UiPhone from "@/components/global/UiPhone.vue";
import UiRangeCalendar from "@/components/global/UiRangeCalendar.vue";
import UiSelect from "@/components/global/UiSelect.vue";
import UiIcon from "./components/global/UiIcon.vue";
import UiSidebar from "@/components/global/UiSidebar.vue";
import UiTextarea from "@/components/global/UiTextarea.vue";
import UiCheckboxBlock from "@/components/global/checkbox-block/UiCheckboxBlock.vue";

//@ts-ignore
import VueVirtualScroller from "vue-virtual-scroller";

// need to be removed as soon as possible
// @ts-ignore
import BootstrapVue3 from "@jcubegroup/bootstrap-vue-3";

import App from "./App.vue";
import checkVersion from "@/scripts/check-version";

(async () => {
  await checkVersion();

  const app = createApp(App)
    .component("Popper", Popper)
    .component("VDatePicker", DatePicker)
    .component("ui-form", Form)
    .component("ui-alert", UiAlert)
    .component("ui-calendar", UiCalendar)
    .component("ui-calendar-flat", UiCalendarFlat)
    .component("ui-icon", UiIcon)
    .component("ui-input", UiInput)
    .component("ui-multi-select", UiMultiSelect)
    .component("ui-phone", UiPhone)
    .component("ui-range-calendar", UiRangeCalendar)
    .component("ui-select", UiSelect)
    .component("ui-sidebar", UiSidebar)
    .component("ui-textarea", UiTextarea)
    .component("ui-checkbox-block", UiCheckboxBlock)
    .directive("maska", maska)
    .directive("expanded", expanded)
    .directive("clipboard", clipboard)
    .directive("confirm", confirm)
    .directive("extendCheckbox", extendCheckbox)
    .directive("handleEsc", handleEsc)
    .directive("popup", popup)
    .directive("tooltip", tooltip)
    .use(VueVirtualScroller)
    .use(router)
    .use(i18n)
    .use(store)
    .use(BootstrapVue3)
    .use(validate);

  app.config.errorHandler = (err, vm, info) => {
    //@ts-ignore
    console.log("Stack:", err.stack);
    console.log("Additional info:", info);
  };

  app.mount("#app");
})();
