<script setup>
import { nextTick, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import API from "@/services/api/API";
import moment from "moment";

const store = useStore();
const router = useRouter();

const showDropdown = ref(false);
const dropdown = ref(null);
const model = ref(null);
const existCurrency = ref(false);

const closeOnBlur = (e) => {
  if (!dropdown.value?.contains(e.relatedTarget)) {
    close();
  }
};

const open = async () => {
  try {
    model.value = await API.core().exchangeRate();

    if (model.value.usd_max_value && model.value.eur_max_value) {
      existCurrency.value = true;
    }

    showDropdown.value = true;

    await nextTick(() => {
      dropdown.value?.focus();
    });
  } catch (error) {
    await store.dispatch("alert/show", {
      title: error.error,
      text: error.message,
      type: "error",
    });
  }
};

const close = () => {
  showDropdown.value = false;
};
</script>

<template>
  <div class="currency">
    <ui-icon class="cursor-pointer" color="var(--bs-white)" name="dollar-sign" size="24" @click="open()"></ui-icon>
    <Transition name="fade">
      <div
        v-if="showDropdown"
        id="dropdown"
        ref="dropdown"
        class="currency__dropdown p-3"
        tabindex="-1"
        @blur="closeOnBlur"
      >
        <div class="fs-16px fw-bold text-gray-1 mb-1">{{ model.date || moment().format("DD.MM.YYYY") }}</div>
        <div v-if="!existCurrency" class="fs-16px fw-bold text-red mt-2 pb-1">{{ $t("global.currency.empty") }}</div>
        <template v-if="existCurrency">
          <div class="divider-element mt-2 mb-2"></div>
          <div
            :class="{
              'text-gray-1': existCurrency,
              'text-gray-4': !existCurrency,
            }"
            class="fs-16px mt-1 mb-1 d-flex justify-content-between"
          >
            <div class="fw-bold">EUR</div>
            <div class="fw-medium">{{ existCurrency ? model.eur_max_value : "" }}</div>
          </div>
          <div :class="{ 'mb-2': existCurrency }" class="divider mt-2"></div>
          <div
            :class="{
              'text-gray-1': existCurrency,
              'text-gray-4': !existCurrency,
            }"
            class="fs-16px mt-1 mb-1 d-flex justify-content-between"
          >
            <div class="fw-bold">USD</div>
            <div class="fw-medium">{{ existCurrency ? model.usd_max_value : "" }}</div>
          </div>
          <div :class="{ 'mb-2': existCurrency }" class="divider mt-2"></div>
        </template>
        <template v-if="existCurrency">
          <div class="fs-16px text-gray-1">
            {{
              $t("global.currency.ratio", {
                ratio: model.eur_usd_ratio,
              })
            }}
          </div>
          <div class="mt-1 fs-16px text-gray-1 fw-bold">{{ model.eur_max_value }}/{{ model.usd_min_value }}</div>
        </template>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.currency {
  position: relative;

  & * {
    line-height: 1.2;
  }

  &__dropdown {
    position: absolute;
    width: 174px;
    top: 40px;
    right: -87px;
    outline: none;
    background: var(--bs-white);
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    letter-spacing: -0.154px;
  }
}

@include media-breakpoint-down(md) {
  .currency {
    &__dropdown {
      right: 0;
    }
  }
}
</style>
