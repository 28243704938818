import tippy from "tippy.js";
import "tippy.js/animations/scale.css";

export default {
  created(el: any, bindings: any) {
    const handler = el.querySelector("[data-popup=handler]");
    const popup = el.querySelector("[data-popup=content]");

    if (handler && popup) {
      popup.classList.add("tippy-popup");

      const instance: any = tippy(handler, {
        content: popup,
        appendTo: () => document.body,
        animation: "scale",
        delay: [400, 100],
        arrow: false,
        interactive: true,
        onMount(instance) {
          instance.popper.classList.add("handless-tippy");
        },
        ...bindings.value,
      });

      popup.addEventListener("click", () => {
        instance.hide();
      });
    }
  },
};
