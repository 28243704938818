import Http from "@/services/api/http";

export default class APIModel {
  protected readonly http = new Http();
  protected readonly resourceUrl: string;

  constructor(resourceUrl: string) {
    this.resourceUrl = resourceUrl;
  }

  get(params = {}): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}`, params);
  }

  post(payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(422).post(`${this.resourceUrl}`, payload);
  }

  patch(id: number | string, payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(422).patch(`${this.resourceUrl}/${id}`, payload);
  }

  put(id: number | string, payload: object): Promise<any> {
    return this.http.ignoreErrorHandler(422).put(`${this.resourceUrl}/${id}`, payload);
  }

  delete(id: number): Promise<any> {
    return this.http.delete(`${this.resourceUrl}/${id}`);
  }

  show(id: number | string): Promise<any> {
    return this.http.ignoreErrorHandler(404, 500).get(`${this.resourceUrl}/${id}`, {});
  }

  getForEdit(id: number | string): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`${this.resourceUrl}/${id}/edit`, {});
  }

  deleteMany(params: object): Promise<any> {
    return this.http.delete(`${this.resourceUrl}`, params);
  }
}
