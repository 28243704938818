import ApiModel from "../APIModel";
import { AxiosResponse } from "axios";

export default class Offers extends ApiModel {
  constructor() {
    super("orders/book");
  }

  save(id: string, payload: object): Promise<AxiosResponse> {
    return this.http.ignoreErrorHandler().post(`v2/${this.resourceUrl}/${id}`, payload);
  }

  calc(id: string, payload: object): Promise<AxiosResponse> {
    return this.http.ignoreErrorHandler().post(`v2/orders/calculate/${id}`, payload);
  }

  show(id: number | string): Promise<any> {
    return this.http.ignoreErrorHandler(500).get(`v2/${this.resourceUrl}/${id}`, {});
  }
}
