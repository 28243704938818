import { ActionContext } from "vuex";
import CacheService from "@/services/CacheService";

interface StateInterface {
  credentials: any;
  hasAccessToken: boolean;
  user: any;
  offers: number;
}

export default {
  namespaced: true,

  state: {
    credentials: (JSON.parse(localStorage.getItem("credentials") as string) as any) || {},
    hasAccessToken: !!localStorage.getItem("credentials") as boolean,
    user: null as any,
    offers: 0,
  } as StateInterface,

  mutations: {
    login(state: StateInterface, credentials: any) {
      state.hasAccessToken = true;
      state.credentials = credentials;
    },
    logout(state: StateInterface) {
      state.credentials = {};
      state.hasAccessToken = false;
    },
    setUser(state: StateInterface, user: any) {
      state.user = user;
    },
    setOffers(state: StateInterface, payload: any) {
      state.offers = payload;
    },
  },

  actions: {
    async login({ commit }: ActionContext<StateInterface, "{}">, payload: any) {
      commit("login", payload);

      await CacheService.clearRouteCache("api");

      localStorage.setItem("credentials", JSON.stringify(payload));
    },
    setOffers({ commit }: ActionContext<StateInterface, "{}">, payload: any) {
      commit("setOffers", payload);
    },
    async logout({ commit }: ActionContext<StateInterface, "{}">) {
      commit("logout");

      await CacheService.clearRouteCache("api");

      localStorage.removeItem("credentials");
      location.href = `${location.origin}/auth/login`;
    },
    setUser({ commit }: ActionContext<StateInterface, "{}">, payload: any) {
      commit("setUser", payload);
    },
  },

  getters: {
    hasAccessToken: (state: StateInterface): boolean => state.hasAccessToken,
    credentials: (state: StateInterface): any => state.credentials,
    user: (state: StateInterface): any => state.user,
    offers: (state: StateInterface): any => state.offers,
  },
};
