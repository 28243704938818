import store from "@/store";

const credentials = store.getters["auth/credentials"];

const meta = {
  module: "errors",
  errorPage: true,
  layout: credentials.user ? "Default" : "Empty",
  withoutCredentials: true,
};

export default [
  {
    path: "/:pathMatch(.*)",
    redirect: `/error/404`,
  },
  {
    path: `/error/404`,
    name: "errors-404",
    component: () => import(/* webpackChunkName: "error" */ "@/modules/errors/404.vue"),
    meta: {
      ...meta,
      titleLocalizationPath: "errors.404",
    },
  },
  {
    path: `/error/403`,
    name: "errors-403",
    component: () => import(/* webpackChunkName: "error" */ "@/modules/errors/403.vue"),
    meta: {
      ...meta,
      titleLocalizationPath: "errors.403",
    },
  },
  {
    path: `/error/500`,
    name: "errors-500",
    component: () => import(/* webpackChunkName: "error" */ "@/modules/errors/500.vue"),
    meta: {
      ...meta,
      titleLocalizationPath: "errors.500",
    },
  },
  {
    path: `/error/504`,
    name: "errors-504",
    component: () => import(/* webpackChunkName: "error" */ "@/modules/errors/504.vue"),
    meta: {
      ...meta,
      titleLocalizationPath: "errors.504",
    },
  },
  {
    path: `/error/unknown`,
    name: "errors-unknown",
    component: () => import(/* webpackChunkName: "error" */ "@/modules/errors/unknown.vue"),
    meta: {
      ...meta,
      layout: "Empty",
      titleLocalizationPath: "errors.unknown",
    },
  },
];
