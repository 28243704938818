import { createRouter, createWebHistory, Router, RouteRecordRaw } from "vue-router";
import RouterMiddleware from "@/router/middleware";

const routes: Array<RouteRecordRaw> = [];
const modules = require.context("./modules", true, /[A-Za-z0-9-_,\s]+\.ts$/i);

modules.keys().forEach((key) => {
  routes.push(...modules(key).default);
});

const router: Router = createRouter({
  history: createWebHistory(),
  routes,
});

const middleware = new RouterMiddleware(router);

middleware.apply();

export default router;
