const meta = {
  module: "auth",
  withoutCredentials: true,
  layout: "Empty",
};

export default [
  {
    path: "/auth/login",
    name: "auth-login",
    component: () => import(/* webpackChunkName: "auth" */ `@/modules/${meta.module}/login.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "auth.login",
    },
  },
  {
    path: "/auth/forgot",
    name: "auth-forgot",
    component: () => import(/* webpackChunkName: "auth" */ `@/modules/${meta.module}/forgot.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "auth.forgot",
    },
  },
  {
    path: "/auth/reset/:token",
    name: "auth-reset",
    component: () => import(/* webpackChunkName: "auth" */ `@/modules/${meta.module}/reset.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "auth.reset",
    },
  },
  {
    path: "/auth/download-agency-contract",
    name: "auth-download",
    component: () => import(/* webpackChunkName: "auth" */ `@/modules/${meta.module}/download-agency-contract.vue`),
    meta: {
      ...meta,
      titleLocalizationPath: "auth.download",
    },
  },
];
