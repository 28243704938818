<template>
  <Field v-slot="{ meta }" v-model="input" :name="name" :rules="rules" :class="{ 'mb-0 pb-0': hideDetails }">
    <div
      :class="{
        'input-icon': !!icon,
        'input-icon-with-text': !!iconText,
        'w-100': true,
        disabled: $attrs.disabled,
      }"
    >
      <ui-icon v-if="icon" :name="icon" size="17" class="inline-icon" />
      <span v-if="!!iconText" class="icon-text">{{ iconText }}</span>
      <textarea
        :id="id"
        ref="inputElement"
        v-model="input"
        :class="{ danger: meta.validated && !meta.valid }"
        :name="name"
        :style="{
          height: props.height,
        }"
        autocomplete="off"
        class="form-control"
        v-bind="$attrs"
        @input="onUpdate"
      />
    </div>
  </Field>
</template>

<script setup>
import { defineEmits, defineExpose, defineProps, ref, watch } from "vue";
import { Field } from "vee-validate";
import { randString } from "@/services/utils";
import cloneDeep from "lodash/fp/cloneDeep";
import UiIcon from "@/components/global/UiIcon";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: () => "",
  },
  name: {
    type: String,
    default: () => "Field" + Math.floor(Math.random() * 100),
  },
  rules: {
    type: String,
    default: () => "",
  },
  type: {
    type: String,
    default: () => "text",
  },
  iconText: {
    type: String,
    default: () => "",
  },
  preText: {
    type: String,
    default: () => "",
  },
  height: {
    type: String,
    default: () => "",
  },
  showErrors: {
    type: Boolean,
    default: true,
  },
  hideDetails: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: [Array, String],
    default: () => null,
  },
});
const id = "input__" + randString(8);

const input = ref(props.modelValue);
const onUpdate = (e) => emit("update:modelValue", e.currentTarget.value);

watch(
  () => cloneDeep(props.modelValue),
  (currentValue) => (input.value = currentValue)
);

const inputElement = ref(null);

defineExpose({
  inputElement,
});
</script>
