import { Store } from "vuex";
import store from "@/store";
import i18n from "@/plugins/i18n";
import { RouteLocationNormalized } from "vue-router";

class LocalizationService {
  private store: Store<unknown>;

  constructor() {
    this.store = store;
  }

  public async load(to: RouteLocationNormalized) {
    const currentLang = this.store.getters["localization/getCurrent"];

    try {
      if (!i18n.global.messages[currentLang].hasOwnProperty(to.meta.module)) {
        const moduleLocale = await import(`@/modules/${to.meta.module}/locales/${currentLang}.ts`);

        i18n.global.setLocaleMessage(currentLang, {
          ...i18n.global.messages[currentLang],
          [to.meta.module as string]: moduleLocale.default,
        });
      }
    } catch (e) {
      console.error(e);
      await Promise.resolve();
    }
  }
}

export default LocalizationService;
